import React, { useState, useEffect } from "react";
import { getDatabase, ref, get, child } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";
import BuyCreditsButton from './BuyCreditsButton'; // Import the new BuyCreditsButton component
import NoDataPlaceholder from './NoDataPlaceholder'; // Assuming you have created a NoDataPlaceholder component

const Reports = () => {
  const [user] = useAuthState(auth);
  const [reportData, setReportData] = useState({
    totalSummarized: 0,
    totalGeneratedAnswers: 0,
    totalEssays: 0,
    totalMathSolved: 0,
    totalParaphrased: 0,
    totalLessonPlans: 0,  // Added for Lesson Planner
    totalWorksheets: 0,   // Added for Worksheet Generator
    quizPerformance: [],
    quizHistory: [],
    homeworkHelperMessages: 0,  // Added for Homework Helper
    teacherAssistantMessages: 0, // Added for Teacher's Assistant
  });
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const database = getDatabase();

  useEffect(() => {
    if (user) {
      fetchReportData();
    }
  }, [user]);

  const fetchReportData = async () => {
    const dbRef = ref(database);
    const summarySnap = await get(child(dbRef, `summaries/${user.uid}`));
    const mocktestSnap = await get(child(dbRef, `mocktests/${user.uid}`));
    const userSnap = await get(child(dbRef, `users/${user.uid}`));

    let totalSummarized = 0;
    let totalGeneratedAnswers = 0;
    let totalEssays = 0;
    let totalMathSolved = 0;
    let totalParaphrased = 0;
    let totalLessonPlans = 0;
    let totalWorksheets = 0;
    let homeworkHelperMessages = 0;
    let teacherAssistantMessages = 0;
    let quizPerformance = [];
    let quizHistory = [];

    const summaryList = summarySnap.exists() ? summarySnap.val() : {};
    const mocktestList = mocktestSnap.exists() ? mocktestSnap.val() : {};

    // Check subscription status
    if (userSnap.exists()) {
      const userData = userSnap.val();
      setIsSubscribed(userData.subscriptionStatus === "subscribed");
      homeworkHelperMessages = userData.homework_helper_message_count || 0;
      teacherAssistantMessages = userData.teacher_assistant_message_count || 0;
    }

    // Count for each tool including new ones
    Object.keys(summaryList).forEach((key) => {
      const tool = summaryList[key].tool;
      if (tool === "Note Summarizer") totalSummarized++;
      if (tool === "Bullet Point Answer Generator") totalGeneratedAnswers++;
      if (tool === "Essay Writer") totalEssays++;
      if (tool === "Math Solver") totalMathSolved++;
      if (tool === "Quillbot") totalParaphrased++;
      if (tool === "Lesson Planner") totalLessonPlans++;
      if (tool === "Worksheet Generator") totalWorksheets++;
    });

    // Quiz data for performance and history
    Object.keys(mocktestList).forEach((key) => {
      const status = mocktestList[key].status || "Not Started";
      if (status !== "Not Started") {
        const quizDate = format(new Date(mocktestList[key].createdAt), 'MMM dd, yyyy');
        quizPerformance.push({
          topic: mocktestList[key].originalText || "Unknown Topic",
          score: mocktestList[key].score || 0,
        });
        quizHistory.push({
          date: quizDate,
          score: mocktestList[key].score || 0,
        });
      }
    });

    setReportData({
      totalSummarized,
      totalGeneratedAnswers,
      totalEssays,
      totalMathSolved,
      totalParaphrased,
      totalLessonPlans,  // Set Lesson Plans
      totalWorksheets,   // Set Worksheets
      quizPerformance,
      quizHistory,
      homeworkHelperMessages, // Set Homework Helper Messages
      teacherAssistantMessages, // Set Teacher Assistant Messages
    });

    setLoading(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Pie chart for tool usage
  const toolUsageData = [
    { name: "Note Summarizer", value: reportData.totalSummarized },
    { name: "Bullet Point Answer Generator", value: reportData.totalGeneratedAnswers },
    { name: "Essay Writer", value: reportData.totalEssays },
    { name: "Math Solver", value: reportData.totalMathSolved },
    { name: "Paraphrasing Tool", value: reportData.totalParaphrased },
    { name: "Mock Test Generator", value: reportData.quizPerformance.length },
    { name: "Lesson Planner", value: reportData.totalLessonPlans },  // Added Lesson Planner
    { name: "Worksheet Generator", value: reportData.totalWorksheets },  // Added Worksheet Generator
  ];

  const hasData = toolUsageData.some((item) => item.value > 0);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF", "#FF3E96", "#3498DB", "#E74C3C"];  // Changed 8 colors

  // Bar chart data for quiz performance
  const quizPerformanceData = reportData.quizPerformance.map((q, index) => ({
    topic: q.topic,
    score: q.score,
  }));

  // Line chart data for quiz history
  const quizHistoryData = reportData.quizHistory.map((q, index) => ({
    date: q.date,
    score: q.score,
  }));

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full">
        <h2 className="text-2xl font-bold text-center mb-6">Advanced Performance Report</h2>

        {/* Gradient Metric Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-8">
          <div className="p-4 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Summarized Notes</h3>
            <p className="text-3xl font-bold">{reportData.totalSummarized}</p>
          </div>
          <div className="p-4 bg-gradient-to-r from-yellow-400 to-orange-500 text-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Generated Answers</h3>
            <p className="text-3xl font-bold">{reportData.totalGeneratedAnswers}</p>
          </div>
          <div className="p-4 bg-gradient-to-r from-pink-400 to-red-500 text-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Essays Written</h3>
            <p className="text-3xl font-bold">{reportData.totalEssays}</p>
          </div>
          <div className="p-4 bg-gradient-to-r from-purple-400 to-indigo-500 text-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Math Problems Solved</h3>
            <p className="text-3xl font-bold">{reportData.totalMathSolved}</p>
          </div>
          {/* Lesson Planner */}
          <div className="p-4 bg-gradient-to-r from-cyan-400 to-teal-500 text-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Lesson Planned</h3>
            <p className="text-3xl font-bold">{reportData.totalLessonPlans}</p>
          </div>
          {/* Worksheet Generator */}
          <div className="p-4 bg-gradient-to-r from-green-400 to-yellow-500 text-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Worksheet Generated</h3>
            <p className="text-3xl font-bold">{reportData.totalWorksheets}</p>
          </div>
          {/* Homework Helper Messages */}
          <div className="p-4 bg-gradient-to-r from-indigo-400 to-purple-500 text-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Homework Helper Messages</h3>
            <p className="text-3xl font-bold">{reportData.homeworkHelperMessages}</p>
          </div>
          {/* Teacher's Assistant Messages */}
          <div className="p-4 bg-gradient-to-r from-red-400 to-pink-500 text-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Teacher's Assistant Messages</h3>
            <p className="text-3xl font-bold">{reportData.teacherAssistantMessages}</p>
          </div>
        </div>

        <h3 className="text-xl font-bold text-center mb-4">Tool Usage Report</h3>
        {/* Tool Usage Pie Chart */}
        <div className="mb-8">
          {hasData ? (
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={toolUsageData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  label
                >
                  {toolUsageData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Legend />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <NoDataPlaceholder text="No Data Available for Tool Usage" />
          )}
        </div>

        <h3 className="text-xl font-bold text-center mb-4">Mock Test Performance</h3>
        {/* Mock Test Performance Bar Chart */}
        <div className="mb-8">
          {quizPerformanceData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={quizPerformanceData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="topic" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="score" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <NoDataPlaceholder text="No Mock Test Performance Data Available" />
          )}
        </div>

        <h3 className="text-xl font-bold text-center mb-4">Quiz Performance Over Time</h3>
        {/* Quiz Performance Over Time Line Chart */}
        <div className="mb-8">
          {quizHistoryData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={quizHistoryData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="score" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <NoDataPlaceholder text="No Quiz History Data Available" />
          )}
        </div>
      </div>
      <BuyCreditsButton />
    </div>
  );
};

export default Reports;

