import React, { useState } from "react";
import { getDatabase, ref, update, get } from "firebase/database"; // Firebase Realtime Database
import { PayPalButton } from "react-paypal-button-v2"; // PayPal button for payment
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

const BuyCreditsButton = () => {
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [numCredits, setNumCredits] = useState(30); // Number of credits to purchase
  const [discountCode, setDiscountCode] = useState(''); // Discount code entered by user
  const [discountPercent, setDiscountPercent] = useState(0); // Discount percentage fetched from Firebase
  const [isCheckingCode, setIsCheckingCode] = useState(false); // Flag for checking discount code
  const database = getDatabase();
  const [user] = useAuthState(auth);

  const updateCreditsInDatabase = async (creditsToAdd) => {
    if (user) {
      const userRef = ref(database, `users/${user.uid}`);
  
      // Fetch current limits from the database
      try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const currentData = snapshot.val();
  
          // Add creditsToAdd to the current limits
          const updatedData = {
            summarizeLimit: (currentData.summarizeLimit || 0) + creditsToAdd,
            essayLimit: (currentData.essayLimit || 0) + creditsToAdd,
            mathSolverLimit: (currentData.mathSolverLimit || 0) + creditsToAdd,
            quizLimit: (currentData.quizLimit || 0) + creditsToAdd,
            answerLimit: (currentData.answerLimit || 0) + creditsToAdd,
            paraphraseLimit: (currentData.paraphraseLimit || 0) + creditsToAdd,
            homework_helper_message_limit: (currentData.homework_helper_message_limit || 0) + 25 * creditsToAdd,
            worksheetLimit: (currentData.worksheetLimit || 0) + creditsToAdd,
            planLimit: (currentData.planLimit || 0) + creditsToAdd,
            teacher_assistant_message_limit: (currentData.teacher_assistant_message_limit || 0) + 25 * creditsToAdd,
            podcastLimit: (currentData.podcastLimit || 0) + creditsToAdd
          };
  
          // Update the database with the new limits
          await update(userRef, updatedData);
  
          toast.success(`Successfully added ${creditsToAdd} credits to each tool!`);
        } else {
          toast.error('User data not found in the database.');
        }
      } catch (error) {
        console.error('Error updating credits:', error);
        toast.error('Failed to update credits.');
      }
    }
  };

  // Function to handle PayPal payment and purchase credits
  const handleBuyCredits = (creditsToAdd) => {
    updateCreditsInDatabase(creditsToAdd);
    setShowModal(false); // Close the modal
  };

  // Handle increasing credits by 30
  const handleIncreaseCredits = () => {
    setNumCredits((prevCredits) => prevCredits + 30);
  };

  // Handle decreasing credits by 30 (minimum 30)
  const handleDecreaseCredits = () => {
    setNumCredits((prevCredits) => (prevCredits > 30 ? prevCredits - 30 : 30));
  };

  // Handle direct input for credits in multiples of 30
  const handleInputChange = (value) => {
    const newValue = Math.max(30, Math.floor(value / 30) * 30); // Ensure it's a multiple of 30
    setNumCredits(newValue);
  };

  // Function to check if the discount code is valid by querying Firebase
  const handleApplyDiscountCode = async () => {
    setIsCheckingCode(true);
    const discountRef = ref(database, 'discount'); // Fixed by passing 'discount' as a string path
    try {
      const snapshot = await get(discountRef);
      console.log(snapshot);
      if (snapshot.exists()) {
        console.log('Path exists'); // Debugging log
        const discountData = snapshot.val();
        if (discountData.code === discountCode) {
          setDiscountPercent(discountData.percent); // Set discount percentage
          toast.success(`Discount code applied! ${discountData.percent}% off.`);
        } else {
          toast.error('Invalid discount code.');
        }
      } else {
        toast.error('No discount data found.');
      }
    } catch (error) {
      console.error('Firebase error:', error); // Log error for debugging
      toast.error('Error applying discount code.');
    } finally {
      setIsCheckingCode(false);
    }
  };

  // Calculate the total cost after applying the discount
  const totalCost = ((numCredits / 30) * 5) * (1 - discountPercent / 100);

  return (
    <div>
      {/* Floating "Buy Credits" Button */}
      <button
        className="fixed bottom-20 right-6 bg-[#14f195] text-white rounded-full p-4 shadow-lg hover:bg-[#0ac882] transition duration-300"
        onClick={() => setShowModal(true)}
      >
        Buy Credits
      </button>


      {/* Buy Credits Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{ zIndex: 999 }}>
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">Buy Credits</h3>

            {/* Input for selecting credits */}
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Enter number of credits (added to each tool):
            </label>
            <div className="flex items-center mb-4">
              <button
                onClick={handleDecreaseCredits}
                className="bg-gray-300 text-gray-700 px-3 py-2 rounded-l-lg hover:bg-gray-400"
              >
                -
              </button>
              <input
                type="number"
                value={numCredits}
                onChange={(e) => handleInputChange(Number(e.target.value))}
                className="w-full px-4 py-2 border text-center"
                min="30"
              />
              <button
                onClick={handleIncreaseCredits}
                className="bg-gray-300 text-gray-700 px-3 py-2 rounded-r-lg hover:bg-gray-400"
              >
                +
              </button>
            </div>

            {/* Input for Discount Code */}
            <label className="block mb-2 text-sm font-medium text-gray-700">Discount Code:</label>
            <div className="flex mb-4">
              <input
                type="text"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
                className="w-full px-4 py-2 border"
                placeholder="Enter discount code"
              />
              <button
                onClick={handleApplyDiscountCode}
                className="bg-green-500 text-white px-4 py-2 ml-2 rounded-lg"
                disabled={isCheckingCode}
              >
                {isCheckingCode ? 'Checking...' : 'Apply'}
              </button>
            </div>

            {/* Display Total Cost */}
            <p className="mb-4">Total Cost: ${totalCost.toFixed(2)}</p>

            <PayPalButton
              amount={totalCost.toFixed(2)} // Use discounted total cost
              onSuccess={() => handleBuyCredits(numCredits)}
              options={{
                clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "USD",
              }}
            />

            <button
              onClick={() => setShowModal(false)}
              className="w-full bg-red-500 text-white px-4 py-2 rounded-lg mt-4 hover:bg-red-600 transition duration-300"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default BuyCreditsButton;
