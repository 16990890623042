import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDatabase, ref, get, set } from "firebase/database"; // Firebase Realtime Database
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion } from 'framer-motion';
import { FcGoogle } from 'react-icons/fc';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate(); // To navigate after login
  const db = getDatabase(); // Initialize Realtime Database

  // Redirect to dashboard if user is already logged in
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Logged in successfully!", userCredential);
        navigate("/dashboard"); // Redirect to dashboard after login
      })
      .catch((error) => {
        setError("Invalid email or password. Please try again.");
        console.error("Error logging in: ", error);
      });
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        const userRef = ref(db, `users/${user.uid}`);

        // Check if user exists in database
        get(userRef).then((snapshot) => {
          if (!snapshot.exists()) {
            // If user doesn't exist, set the default limits for tools
            set(ref(db, `users/${user.uid}`), {
              answerLimit: 2,
              essayLimit: 2,
              mathSolverLimit: 2,
              quizLimit: 2,
              summarizeLimit: 2,
              paraphraseLimit: 2,
              homework_helper_message_limit: 50,
              worksheetLimit:2,
              planLimit: 2,
              podcastLimit: 2,
              teacher_assistant_message_limit: 50
            }).then(() => {
              console.log("New user created with default limits.");
              navigate("/dashboard"); // Redirect to dashboard after setting limits
            }).catch((error) => {
              console.error("Error setting default limits: ", error);
              setError("Error setting user limits. Please try again.");
            });
          } else {
            // If user exists, proceed to dashboard
            navigate("/dashboard");
          }
        }).catch((error) => {
          console.error("Error checking user in database: ", error);
          setError("Error logging in with Google. Please try again.");
        });
      })
      .catch((error) => {
        setError("Google Sign-In failed. Please try again.");
        console.error("Error signing in with Google: ", error);
      });
  };

  if (loading) {
    return <div>Loading...</div>; // Optional: Add a spinner or loading state
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-6">Login</h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your email"
              required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your password"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300"
          >
            Login
          </button>
        </form>

        <div className="my-4 flex items-center justify-center">
          <span className="text-gray-500">or</span>
        </div>
        {/* Google Sign-In Button */}
        <button
          onClick={googleSignIn}
          className="flex items-center justify-center w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <FcGoogle className="mr-2" size={24} /> {/* Google icon */}
          Sign in with Google
        </button>


        <div className="mt-4 text-center">
          <Link to="/signup" className="text-blue-600 hover:underline">
            Don't have an account? Sign up here.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
