import React, { useRef, useState } from "react";
import SubscribeSchool from "./SubscribeSchool";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRight } from 'react-icons/fa'; // Importing icons

const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
    {/* <div className="relative overflow-hidden bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 py-12 sm:py-20 flex justify-center items-center text-white">
      <div className="text-center space-y-4 px-6 sm:px-12">
        <h2 className="text-4xl sm:text-5xl font-bold animate-fadeIn">Empower Your School with AI</h2>
        <p className="text-lg sm:text-xl font-medium animate-fadeIn delay-200">Join the revolution in education! Unlock AI-powered tools for your students and teachers.</p>
        <button
          className="bg-white text-blue-600 font-bold px-6 py-3 sm:py-4 sm:px-8 rounded-full hover:bg-gray-100 transition duration-300 animate-fadeIn delay-400"
          onClick={() => setModalOpen(true)}
        >
          Subscribe Now <FaArrowRight className="inline-block ml-2" />
        </button>
      </div>

      <div className="absolute top-0 left-0 w-24 h-24 sm:w-32 sm:h-32 bg-green-400 opacity-50 rounded-full animate-pulse-fast"></div>
      <div className="absolute bottom-0 right-0 w-24 h-24 sm:w-32 sm:h-32 bg-purple-400 opacity-50 rounded-full animate-pulse-slow"></div>
      <div className="absolute top-0 right-1/4 w-16 h-16 sm:w-24 sm:h-24 bg-green-400 opacity-50 rounded-full animate-pulse-slow"></div>

      <SubscribeSchool class="text-black" isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div> */}

    <footer className="bg-gray-900 text-gray-400 py-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start md:items-center space-y-8 md:space-y-0 px-4">
        
        {/* Left Column - Logo and Description */}
        <div className="flex flex-col items-start space-y-4 md:space-y-0 md:w-1/3">
          <img
            src="/images/2.png"
            width="250px" // Replace with your logo path
            alt="StudentAI Tools Logo"
            className="h-auto"
          />
          <p className="text-sm">
            Boost your academic performance with AI-powered tools for students. Get instant help with summarizing notes, solving math problems, generating essays, creating mock tests, and more.
          </p>
        </div>

        {/* Center Column - AI Tools */}
        <div className="md:w-1/3">
          <h3 className="text-white font-semibold mb-4">Teacher Tools</h3>
          <ul className="space-y-2">
            <li><Link to="/lesson-planner" className="hover:text-white">AI Lesson Planner</Link></li>
            <li><Link to="/worksheet-generator" className="hover:text-white">AI Worksheet Generator</Link></li>
            <li><Link to="/mock-test-generator" className="hover:text-white">Quiz Generator</Link></li>
            <li><Link to="/teacher-assistant" className="hover:text-white">AI Teacher's Assistant</Link></li>
          </ul>
        </div>
        
        {/* Center Column - AI Tools */}
        <div className="md:w-1/3">
          <h3 className="text-white font-semibold mb-4">Student Tools</h3>
          <ul className="space-y-2">
            <li><Link to="/math-solver" className="hover:text-white">Math Solver</Link></li>
            <li><Link to="/essay-writer" className="hover:text-white">AI Essay Writer</Link></li>
            <li><Link to="/note-summarizer" className="hover:text-white">Note Summarizer</Link></li>
            <li><Link to="/mock-test-generator" className="hover:text-white">Mock Test Generator</Link></li>
            <li><Link to="/quillbot" className="hover:text-white">AI Paraphrasing Tool</Link></li>
            <li><Link to="/learn-with-podcast" className="hover:text-white">Learn with Podcast</Link></li>
            <li><Link to="/homework-helper" className="hover:text-white">AI Homework Helper</Link></li>
            <li><Link to="/bullet-point-generator" className="hover:text-white">Bullet Point Answer Generator</Link></li>
          </ul>
        </div>

        {/* Right Column - Contact and Company */}
        <div className="md:w-1/3">
          <h3 className="text-white font-semibold mb-4">Company</h3>
          <ul className="space-y-2">
            <li><Link to="/blog" className="hover:text-white">Blog</Link></li>
            <li><a href="https://studentaitools.com/our-story" className="hover:text-white">Our story</a></li>
            <li><a href="https://studentaitools.com/contact-us" className="hover:text-white">Contact Us</a></li>
            <li><a href="https://studentaitools.com/privacy-policy" className="hover:text-white">Privacy Policy</a></li>
            <li><a href="https://studentaitools.com/terms-conditions" className="hover:text-white">Terms & Conditions</a></li>
          </ul>
        </div>

        {/* Contact Info */}
        <div className="w-full text-center md:text-left md:w-1/3">
          <h3 className="text-white font-semibold mb-4">Get In Touch</h3>
          <p><a href="mailto:info@studentaitools.com" className="hover:text-white">info@studentaitools.com</a></p>

          <h3 className="text-white font-semibold mb-4">Supported By</h3>
          <div className="grid grid-cols-2 gap-4 mb-4 sm:grid-cols-1">
            <img
              src="/images/MS_Startup_Badge_Dark.jpg"
              width="250px" 
              alt="Microsoft for Startup"
              className="h-auto"
            />
            <img
              src="/images/aws_lift.png"
              width="250px" 
              alt="AWS Lift"
              className="h-auto"
            />
          </div>
        </div>
      </div>

      {/* Copyright Info */}
      <div className="text-center text-gray-500 mt-8">
        <p>Copyright © 2024 Geekpoint Technologies Pvt. Ltd.</p>
      </div>
    </footer>
  </>
  );
};

export default Footer;