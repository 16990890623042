import React, { useState, useEffect } from "react";
import { generatePodcastScript } from "../openaiService"; // The function we'll define below
import { getDatabase, ref, push, set, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BuyCreditsButton from './BuyCreditsButton'; // Import the new BuyCreditsButton component
import Select from 'react-select';
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import { Helmet } from 'react-helmet';
import { FaClipboard, FaPause, FaPlay, FaPaperclip,FaFileUpload, FaKeyboard, FaListAlt, FaSyncAlt, FaPen, FaClipboardList, FaQuestionCircle } from "react-icons/fa";
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';
import { Link, useNavigate, useLocation, useParams} from "react-router-dom";
import { extractTextFromPDF, extractText} from "../fileService";
import Download from "./Download";

const LearnWithPodcast = () => {
  const [audioLength, setAudioLength] = useState(2); // Default number of questions
  const [educationLevel, setEducationLevel] = useState("graduate");
  const [creativity, setCreativity] = useState("medium");
  const [topic, setTopic] = useState("");
  const [speakers, setSpeakers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [script, setScript] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [podcastLimit, setPodcastLimit] = useState(2); // Default limit
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [user] = useAuthState(auth);
  const database = getDatabase();
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // To get state passed from Documents
  const [selectedSpeakers, setSelectedSpeakers] = useState([1,4]);
  const [playingSpeaker, setPlayingSpeaker] = useState(null);
  const { podcastId } = useParams();

  const speakerOptions = [
    { id: 1, name:"Curious Carl", description: "University professor, Asks technical well-researched questions. Gets into the details.", image:'/images/carl.jpg', audioUrl:'/audios/0001/carl.mp3' },
    { id: 4, name:"Inquisitive Ivy", description: "University student, asks insightful questions.", image:'/images/ivy.jpg', audioUrl:'/audios/0001/ivy.mp3' },
    { id: 2, name:"Teacher Tina", description: "High school teacher, makes complex topics understandable.", image:'/images/tina.jpg', audioUrl:'/audios/0001/tina.mp3' },
    { id: 3, name:"Humorous Hank", description: "Comedian, lighthearted and provocative with humor.", image:'/images/hank.jpg', audioUrl:'/audios/0001/hank.mp3' },
  ];

  const audioRefs = speakerOptions.reduce((acc, speaker) => {
    acc[speaker.id] = React.createRef();
    return acc;
  }, {});

  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    try{
      if (file) {
        setUploadedFile(file);
        toast.info('Processing PDF...', {
          progress: 0,
          autoClose: false, 
          isLoading: true
        });
        const extractedText = await extractText(file);
        if(extractedText){
          toast.dismiss();
          toast.success('Completed!');
          setTopic(extractedText); // Set the extracted text as the topic
        }
      }
    }catch(error){
      console.log(error);
      const extractedText = await extractTextFromPDF(file);
      console.log(extractedText)
        if(extractedText){
          toast.dismiss();
          toast.success('Completed!');
          setTopic(extractedText); // Set the extracted text as the topic
        }
    }finally{
      toast.dismiss();
    }
  };

  const handlePlayPause = (speakerId) => {
    const currentAudio = audioRefs[speakerId].current;

    if (playingSpeaker === speakerId) {
      // Pause the currently playing audio
      currentAudio.pause();
      setPlayingSpeaker(null);
    } else {
      // Pause any currently playing audio
      if (playingSpeaker !== null) {
        audioRefs[playingSpeaker].current.pause();
      }
      // Play the selected audio
      currentAudio.play();
      setPlayingSpeaker(speakerId);
    }
  };

  const handleCheckboxChange = (speakerId) => {
    console.log(selectedSpeakers)
    if (selectedSpeakers.includes(speakerId)) {
      setSelectedSpeakers(selectedSpeakers.filter((id) => id !== speakerId));
    } else {
      setSelectedSpeakers([...selectedSpeakers, speakerId]);
    }
  };
  const handleAudioEnd = () => {
    setPlayingSpeaker(null);
  };

  useEffect(() => {
    if (user) {
      const fetchPodcast = async () => {
        const podcastRef = ref(database, `podcasts/${user.uid}/${podcastId}`);
        const podcastSnap = await get(podcastRef);
        if (podcastSnap.exists()) {
          const podcast = podcastSnap.val() 
          setScript(podcast.script);
          setAudioUrl(podcast.filePath);
          setViewMode(true);
        }else{
          setScript('');
          setViewMode(false);
        }
      };
      fetchPodcast();
      const fetchUserData = async () => {
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setPodcastLimit(userData.podcastLimit || 2); // Initialize limit if missing
        }
      };
      fetchUserData();
    }

  const ws = new WebSocket('wss://app.studentaitools.com/socket'); // Replace with your WebSocket URL
    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data); // Attempt to parse the data as JSON
        if (data && data.success && data.message=='completed') {
          toast.success(data.message);  
          setScript(data.script);
          setAudioUrl(data.filePath);
        }
      } catch (error) {
        console.log('Received a non-JSON message:', event.data); // Handle non-JSON messages (plain text)
        // Optionally, you can display or log the plain text message here if needed
      }
    };    

    return () => ws.close(); // Clean up on component unmount

  }, [user, database,location]);


  const handleGeneratePodcast = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }
    if (!topic) {
      toast.error("Please enter a topic.");
      setLoading(false);
      return;
    }
    console.log(selectedSpeakers)
    if (selectedSpeakers.length <2) {
      toast.error("Please select at least two speaker.");
      setLoading(false);
      return;
    }
    const totalCreditsNeeded = Math.ceil(audioLength/2);
    // Check if user has enough credits
    if (podcastLimit < totalCreditsNeeded) {
      toast.error(`You need ${totalCreditsNeeded - podcastLimit} more credits to generate this podcast.`);
      setLoading(false);
      return;
    }

    if (podcastLimit <= 0) {
      toast.error("You have reached the free limit of Podcast. Buy credits for creating more Podcast!.");
      setLoading(false);
      return;
    }

    setLoading(true);
    setScript("");

    try {
      var extractedText;
      if (uploadedFile) {
        extractedText = await extractTextFromPDF(uploadedFile);
        setTopic(extractedText); // Set the extracted text as the topic
      }
      if (user) {
        const podcastRef = push(ref(database, `podcasts/${user.uid}`));
        const podcastId = podcastRef.key; // Get the newly generated podcast ID
        const podcastData = {
          topic: topic|| extractedText,
          audioLength: audioLength,
          createdAt: new Date().toISOString(),
          state: 'processing', // Initially set state to 'processing'
          speakers: selectedSpeakers
        };
        await set(podcastRef, podcastData);
        await generatePodcastScript(user.uid,user.email,podcastId, topic || extractedText, selectedSpeakers, audioLength, educationLevel, creativity);

        toast.success(
          <>
            Podcast generation started. You will receive update on your email and{' '}
            <Link to="/document-queue" style={{ color: 'blue', textDecoration: 'underline' }}>
              Documents Queue
            </Link>{' '}
            page once Podcast is ready.
          </>
        );        
        // Deduct the required credits from the podcastLimit
        const newLimit = podcastLimit - 1;
        setPodcastLimit(newLimit);
        const userRef = ref(database, `users/${user.uid}`);
        await update(userRef, { podcastLimit: newLimit });
      }

    } catch (error) {
      console.error("Error generating podcast script:", error);
      toast.error("Error generating script. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    let podcastScript = document.getElementById('podcastScript').innerHTML;
    
    if (!podcastScript) {
      toast.error("No content to copy!"); // Show error if there's no answer generated
      return;
    }
  
    const blob = new Blob([podcastScript], { type: 'text/html' });
    const clipboardItem = new ClipboardItem({ 'text/html': blob });
  
    navigator.clipboard.write([clipboardItem])
      .then(() => {
        toast.success("Podcast script copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy Podcast script:", error);
        toast.error("Failed to copy Podcast script.");
      });
  };
  
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = audioUrl;
    link.download = "podcast.mp3";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Utility function to inject Tailwind classes into lesson plain HTML content
const applyTailwindClasses = (htmlContent) => {
    return htmlContent
      .replace(/<h1>/g, '<h1 class="text-2xl font-bold mb-4">')
      .replace(/<h2>/g, '<h2 class="text-xl font-semibold mb-3">')
      .replace(/<h3>/g, '<h3 class="text-lg font-semibold mb-2">')
      .replace(/<p>/g, '<p class="mb-4 text-base leading-relaxed">')
      .replace(/<ol>/g, '<ol class="list-decimal pl-6 mb-4">')
      .replace(/<ul>/g, '<ul class="list-disc pl-6 mb-4">')
      .replace(/<li>/g, '<li class="mb-2">');
  };

  return (
    <>
    <Helmet>
      <title>Learn with Podcast - Generate Engaging AI-Powered Podcasts | StudentAITools</title>
      <meta
        name="description"
        content="Generate engaging and customized podcasts instantly with our AI-powered Learn with Podcast tool. Perfect for students and educators exploring topics in a dynamic way."
      />
    </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-start bg-gray-100">

        <div className="w-full max-w-5xl flex justify-between items-center mb-4">
          {viewMode && (
              <button
                onClick={() => navigate("/documents")}
                className="btn btn-active mt-4"
              >
                &larr; Back to Documents
              </button>
            )}

        {!viewMode && (
              <p className="text-sm text-gray-600 text-center ml-4 mt-4">
              Note: 1 Credit = 2 Minute
            </p>
            )}

            {user && (
              <div className="text-right">
                <span className="bg-green-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  Credits Left: {podcastLimit}
                </span>
              </div>
            )}
        </div>

        <h1 className="text-3xl font-bold text-center mb-4">Learn with Podcast</h1>
        <p className="text-lg text-gray-600 text-center mb-8">Enter a topic and select speakers to generate engaging podcast discussions, and learn complex topics easily.</p>
        
        {!viewMode && (
        <motion.div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        viewport={{ once: true, amount: 0.1 }}>

    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Podcast Topic</label>
      <div className="flex items-center border rounded-lg relative">
      <textarea
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          rows="6"
          placeholder="Enter your topic here..."
        ></textarea>
      </div>
    </div>
    <div className="flex justify-between items-center my-4">
      <label className="group cursor-pointer flex items-center">
        <FaFileUpload className="text-gray-500 hover:text-gray-700 h-5 w-5 mr-2" />
        Upload PDF
        {/* File Input Hidden */}
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          className="hidden"
        />
      </label>

      <div>
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Audio Length (in Minutes)
        </label>
        <input
          type="number"
          value={audioLength}
          onChange={(e) => setAudioLength(e.target.value)}
          className="w-full px-4 py-2 border rounded-lg mb-2"
          placeholder="Enter Audio Length"
        />
      </div>
    </div>
      {/* Advanced Settings collapsible menu */}
  <div tabIndex="0" className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-4 w-full">
    <input type="checkbox" />
    <div className="collapse-title text-sm font-medium">
      Advanced Settings
    </div>
    <div className="collapse-content">
      {/* Dropdown for Education Level */}
      <div className="my-2">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Education Level
        </label>
        <select
          className="w-full px-4 py-2 border rounded-lg"
          onChange={(e) => setEducationLevel(e.target.value)}
        >
          <option value="high school">High School</option>
          <option value="undergraduate">College</option>
          <option value="graduate">Graduate</option>
        </select>
      </div>

      {/* Dropdown for Creativity */}
      <div className="my-2">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Creativity
          <span className="ml-2 cursor-pointer group relative tooltip tooltip-right" data-tip="Low Creativity produce content that closely match the prompt. High Creativity create content that are diverse and creative but less connected to the prompt.">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10c0 4.418-3.582 8-8 8S2 14.418 2 10 5.582 2 10 2s8 3.582 8 8zm-8 3a1 1 0 110 2 1 1 0 010-2zm1-8a1 1 0 00-2 0v5a1 1 0 002 0V5z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </label>
        <select
          className="w-full px-4 py-2 border rounded-lg"
          onChange={(e) => setCreativity(e.target.value)}
        >
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </select>
      </div>
    </div>
  </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-4">Select Speakers</label>
            <div className="grid sm:grid-cols-2 gap-4 grid-cols-1">
              {speakerOptions.map((speaker) => (
                <div 
                  key={speaker.id} 
                  className={`bg-white shadow-lg rounded-lg p-4 flex flex-col items-center text-center cursor-pointer transition-all 
                  ${selectedSpeakers.includes(speaker.id) ? 'border-2 border-blue-600' : ''}`}
                  onClick={() => handleCheckboxChange(speaker.id)}
                >
                  {/* Hidden Checkbox */}
                  <input
                    type="checkbox"
                    checked={selectedSpeakers.includes(speaker.id)}
                    onChange={() => handleCheckboxChange(speaker.id)}
                    className="hidden"
                  />
                  
                  <img
                    src={speaker.image}
                    alt={speaker.name}
                    className="h-24 w-24 rounded-full mb-2"
                  />
                  <h3 className="text-lg font-bold mb-1">{speaker.name}</h3>
                  <p className="text-gray-600 mb-2">{speaker.description}</p>
                  <button
                    onClick={() => handlePlayPause(speaker.id)}
                    className="bg-green-600 text-white p-2 rounded-full hover:bg-green-700 transition"
                  >
                    {playingSpeaker === speaker.id ? <FaPause /> : <FaPlay />}
                  </button>
                  <audio ref={audioRefs[speaker.id]} src={speaker.audioUrl} onEnded={handleAudioEnd} />
                </div>
              ))}
            </div>
          </div>
          
          <button type="submit" className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 flex justify-center items-center" disabled={loading}
          onClick={handleGeneratePodcast}>
            {loading ? (
              <div className="flex items-center space-x-2">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>Generating...</span>
              </div>
            ) : "Generate Podcast"}
          </button>
          
          {script && (
            <div className="bg-gray-100 p-4 rounded-lg mt-4">
              <h2 className="text-lg font-bold mb-2">Generated Podcast</h2>
              <pre className="text-gray-700 whitespace-pre-wrap">{script}</pre>
            </div>
          )}
        </motion.div>
       )}

       {/* Output for the generated podcast */}
      <div className="bg-white shadow-lg rounded-lg p-8 mt-8 max-w-5xl w-full">
        <h2 className="text-lg font-bold mb-4">Generated Podcast</h2>

        {/* Generated podcast script */}
        <div className="bg-gray-100 p-4 rounded-lg h-72 lg:h-72 relative overflow-auto">
          <div className="text-gray-700" id="podcastScript">
            {script ? (
              JSON.parse(script).map((segment, index) => {
                const speaker = speakerOptions.find(s => s.id === parseInt(segment.speaker));
                return (
                  <p key={index} className="mb-2">
                    <strong>{speaker.name}</strong>: {segment.text}
                  </p>
                );
              })
            ) : (
              "Your generated podcast script will appear here..."
            )}
          </div>
        </div>

        {/* Audio Player */}
        {audioUrl && (
        <div className="mt-6">
          <audio controls className="w-full">
            <source src={audioUrl} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
      {!audioUrl && (
        <div className="mt-6">
          <audio controls className="w-full">
            <source src="" type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

        {/* Buttons below the result */}
        <div className="flex justify-between mt-4">
          {/* Download Button */}
          <button
            onClick={handleDownload}
            className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
          >
            Download
          </button>

          {/* Copy to Clipboard Button */}
          <button
            onClick={handleCopy}
            className="text-gray-500 hover:text-gray-700 flex items-center"
            title="Copy to Clipboard"
          >
            <FaClipboard className="text-xl text-gray-600 hover:text-blue-600" />
          </button>
        </div>
      </div>


        <Feedback tool={{"name":"Learn with Podcast"}} />

        {!viewMode && (
          <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, ease: 'easeOut' }}
                  viewport={{ once: true, amount: 0.1 }}>
            <h2 className="text-2xl font-bold mb-4">What is the Learn with Podcast Tool?</h2>
            <p className="text-gray-700 mb-4">
              The Learn with Podcast tool by StudentAITools allows you to generate engaging and educational podcasts on any topic you choose. By leveraging AI, this tool can create podcasts that are not only informative but also customized to match different speaker personalities. Perfect for students, educators, and lifelong learners looking to explore topics in a fun, dynamic way.
            </p>

            <h3 className="text-xl font-semibold mb-2">Why Use the Learn with Podcast Tool?</h3>
            <table className="min-w-full table-auto border-collapse border border-gray-200 mb-4">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 border border-gray-200">Benefit</th>
                  <th className="px-4 py-2 border border-gray-200">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2 border border-gray-200 font-bold">Topic Variety</td>
                  <td className="px-4 py-2 border border-gray-200">
                    Generate podcasts on any subject of your choice, from science and history to pop culture and more.
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border border-gray-200 font-bold">Diverse Speaker Personalities</td>
                  <td className="px-4 py-2 border border-gray-200">
                    Choose from four unique speaker styles to create a lively and varied conversation.
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border border-gray-200 font-bold">Educational Engagement</td>
                  <td className="px-4 py-2 border border-gray-200">
                    Great for students and educators who want to explore topics in an audio format that feels like a real discussion.
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border border-gray-200 font-bold">Audio Customization</td>
                  <td className="px-4 py-2 border border-gray-200">
                    Each speaker can have a distinct voice using Azure Text-to-Speech, making the podcast sound authentic and engaging.
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border border-gray-200 font-bold">Podcast Generation in Minutes</td>
                  <td className="px-4 py-2 border border-gray-200">
                    Quickly generate podcasts with just a few inputs, saving time while creating rich audio content.
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-xl font-semibold mb-2">How to Use the Learn with Podcast Tool</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
              <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
                <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
                  <FaKeyboard className="text-blue-600" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">Step 1</h3>
                  <p className="text-gray-600">
                    Enter your topic of interest for the podcast.
                  </p>
                </div>
              </div>

              <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
                <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
                  <FaListAlt className="text-green-600" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">Step 2</h3>
                  <p className="text-gray-600">
                    Select up to four speakers to add personality to the discussion.
                  </p>
                </div>
              </div>

              <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
                <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
                  <FaSyncAlt className="text-yellow-600" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">Step 3</h3>
                  <p className="text-gray-600">
                    Click "Generate" and watch as your podcast script and mp3 podcast audio is created.
                  </p>
                </div>
              </div>

              <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
                <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
                  <FaPen className="text-red-600" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">Step 4</h3>
                  <p className="text-gray-600">
                    Download and share your AI-generated podcast with your audience.
                  </p>
                </div>
              </div>
            </div>

            <h3 className="text-xl font-semibold mb-2">Who Can Benefit from the Learn with Podcast Tool?</h3>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li><strong>Students:</strong> Perfect for students who want to learn on the go and explore topics through engaging audio content.</li>
              <li><strong>Educators:</strong> Teachers can create podcasts as supplementary material for their classes or as a resource for students.</li>
              <li><strong>Podcasters:</strong> Ideal for podcasters looking for quick topic inspiration or additional content ideas.</li>
              <li><strong>Lifelong Learners:</strong> Great for anyone who loves to learn and wants to discover new topics in a fun and accessible format.</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools for Podcast Generation?</h3>
            <p className="text-gray-700 mb-4">
              StudentAITools offers an intuitive and versatile AI platform that makes content creation effortless. Our Learn with Podcast tool empowers you to generate unique and dynamic podcasts, whether for personal growth, education, or entertainment.
            </p>

            <h3 className="text-xl font-semibold mb-2">Best Free Article to Audio Summary Tools</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
              {[
                {
                  title: 'Recast AI',
                  description: 'Turn your want-to-read articles into rich audio summaries.',
                  link: 'https://www.letsrecast.ai',
                  isStudentTool: false
                },
                {
                  title: 'NotebookLM',
                  description: 'Transform your text into lively audio conversations with AI hosts in NotebookLM.',
                  link: 'https://notebooklm.google.com',
                  isStudentTool: false
                },
                {
                  title: 'Illuminate',
                  description: 'Convert complex academic papers into easy-to-understand audio podcasts with Google’s Illuminate tool.',
                  link: 'https://illuminate.google.com',
                  isStudentTool: false
                },
                {
                  title: 'Podial.AI',
                  description: 'Turn documents into engaging podcast discussions, and learn complex topics easily.',
                  link: 'https://podial.ai',
                  isStudentTool: false
                }
              ].map((tool, index) => (
                <div
                  key={index}
                  className={`p-6 rounded-lg shadow-lg transition-shadow duration-300 cursor-pointer ${
                    tool.isStudentTool
                      ? 'bg-green-500 text-white border-blue-500 relative'
                      : 'bg-white text-gray-800 hover:border-blue-500 hover:shadow-2xl'
                  } border-2`}
                >
                  {tool.isStudentTool && (
                    <span className="absolute top-0 right-0 bg-white text-gray-800 py-1 px-2 rounded-lg">
                      {tool.badge}
                    </span>
                  )}
                  <h3 className="text-xl font-semibold mb-3">{tool.title}</h3>
                  <p>{tool.description}</p>
                  
                  {/* Visit Button */}
                  <div className="mt-4">
                    <a
                      href={tool.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
                    >
                      Visit
                    </a>
                  </div>
                </div>
              ))}
            </div>

          </motion.div>
        )}
        <NewsletterCard/>
        
        {user && (
            <BuyCreditsButton />
        )}
      </div>
      {!viewMode && (
        <Footer />
        )}
      <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default LearnWithPodcast;
