import React, { useState, useEffect } from "react";
import { generateEssay } from "../openaiService"; // OpenAI service for generating essays
import { getDatabase, ref, push, set, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BuyCreditsButton from './BuyCreditsButton'; // Import the new BuyCreditsButton component
import { FaClipboard, FaKeyboard, FaPen, FaSyncAlt, FaListAlt, FaGraduationCap, FaBriefcase, FaPenFancy, FaBlog, FaSearch} from "react-icons/fa";
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import { Helmet } from 'react-helmet';
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';
import Download from "./Download";

const MAX_TOKENS = 2000; // OpenAI max tokens for generating essays

const EssayWriter = () => {
  const [topic, setTopic] = useState("");
  const [wordCount, setWordCount] = useState(500); // Default word count
  const [essay, setEssay] = useState("");
  const [paragraphs, setParagraphs] = useState(3);
  const [educationLevel, setEducationLevel] = useState("High School");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [essayLimit, setEssayLimit] = useState(2); // Free limit for unsubscribed users
  const [isSubscribed, setIsSubscribed] = useState(false); // Subscription status
  const [isModalOpen, setModalOpen] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation(); // To get state passed from Documents
  var tool= { name: "Essay Writer"}

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userRef = ref(getDatabase(), `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsSubscribed(userData.subscriptionStatus === "subscribed");
          if (userData.essayLimit !== undefined) {
            setEssayLimit(userData.essayLimit);
          } else {
            await update(userRef, { essayLimit: 2 }); // Initialize limit
          }
        }
      };
      fetchUserData();
    }

    // If essay is passed from Documents, enable view mode and set the essay
    if (location.state?.essay) {
      setEssay(location.state.essay);
      setViewMode(true);
    }else{
      setEssay('');
      setViewMode(false);
    }
  }, [user, location]);

  // Handle generating the essay
  const handleGenerateEssay = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }
    setError("");
    setEssay("");
    setLoading(true);

    if (!topic.trim()) {
      setError("Please enter a topic.");
      setLoading(false);
      return;
    }

    if (essayLimit <= 0) {
      toast.error(
        <span>
          Buy credits for creating more essays!
        </span>
      );
      setLoading(false);
      return;
    }

    try {
      const result = await generateEssay(topic, paragraphs, educationLevel);
      setEssay(result);

      // Save the essay to Firebase under the logged-in user's uid
      if (user) {
        const essayRef = push(ref(getDatabase(), `summaries/${user.uid}`));
        await set(essayRef, {
          originalText: topic,
          summary: result,
          paragraphs: paragraphs,
          educationLevel: educationLevel,
          createdAt: new Date().toISOString(),
          tool: "Essay Writer"
        });

        toast.success("Saved to Documents!");

        // Deduct from the free limit if not subscribed
        if (!isSubscribed) {
          const newLimit = essayLimit - 1;
          setEssayLimit(newLimit);
          const userRef = ref(getDatabase(), `users/${user.uid}`);
          await update(userRef, { essayLimit: newLimit });
        }
      }
    } catch (error) {
      setError("There was an issue generating the essay. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle word count change from slider or input box
  const handleWordCountChange = (value) => {
    const newValue = Math.min(value, MAX_TOKENS);
    setWordCount(newValue);
  };

  const handleDownload = () => {
    if (!essay) {
      toast.error("No content to download!");  // Show error if there's no essay generated
      return;
    }
  
    const blob = new Blob([essay], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.href = url;
    link.download = `${topic || "generated-essay"}.txt`;  // Filename based on the topic
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleCopy = () => {
    if (essay) {
      navigator.clipboard.writeText(essay)
        .then(() => {
          toast.success("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
          toast.error("Failed to copy text.");
        });
    } else {
      toast.error("No text to copy!");
    }
  };

  return (
    <>
    <Helmet>
        <title>AI Essay Writer for Students - Student AI Tools</title>
        <meta
          name="description"
          content="Craft high-quality essays effortlessly with our AI essay writer. Enjoy the benefits of an AI essay writer free for students, making writing assignments a breeze!"
        />
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        {/* Back to Documents button - left-aligned */}
        <div className="w-full max-w-5xl flex justify-between items-center mb-4">
          {viewMode && (
            <button
              onClick={() => navigate("/documents")}
              className="btn btn-active mt-4"
            >
              &larr; Back to Documents
            </button>
          )}
          
        {user && (
          <div className="text-right">
            <span className="bg-green-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Credit Left: {essayLimit}
            </span>
          </div>
          )}
      </div>
  
  
        <h1 className="text-3xl font-bold text-center mb-4">AI Essay Writer for Students</h1>
        <p className="text-lg text-gray-600 text-center mb-8">
          Enter a topic, we'll write an essay
        </p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        {/* Main container for two-column layout */}
        <motion.div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full flex flex-col md:flex-row"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        viewport={{ once: true, amount: 0.1 }}>
          {/* Left column: Essay Input */}
  
          {!viewMode && (
            <div className="w-full md:w-1/2 pr-4">
              <h2 className="text-lg font-bold mb-4">Essay Topic</h2>
  
              <form onSubmit={handleGenerateEssay}>
                {/* Essay Topic Input */}
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    What's your essay about?
                  </label>
                  <textarea
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    rows="4"
                    placeholder="Type your essay topic here"
                    required
                  ></textarea>
                </div>
  
                {/* Number of Paragraphs */}
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    Number of Paragraphs
                  </label>
                  <select
                    value={paragraphs}
                    onChange={(e) => setParagraphs(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  >
                    {[...Array(10)].map((_, i) => (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </div>
  
                {/* Education Level */}
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    Education Level
                  </label>
                  <select
                    value={educationLevel}
                    onChange={(e) => setEducationLevel(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  >
                    <option value="High School">High School</option>
                    <option value="College">College</option>
                    <option value="Graduate School">Graduate</option>
                  </select>
                </div>
  
                {/* Generate Button */}
                <button type="submit" className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 flex justify-center items-center" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Generating...</span>
                    </div>
                  ) : "Generate"}
                </button>
              </form>
            </div>
          )}
  
          {/* AI Output result */}
          <div className={viewMode ? "w-full pl-4" : "w-full lg:w-1/2 pl-4"}>
            <h2 className="text-lg font-bold mb-4">AI Output</h2>
  
            {/* generated essay output */}
            <div className="bg-gray-100 p-4 rounded-lg h-72 lg:h-72 relative overflow-auto whitespace-pre-line">
              {/* generated essay */}
              <p className="text-gray-700" id="essay">
                {essay || "Your generated essay will appear here..."}
              </p>
            </div>
  
            {/* Buttons below the result */}
            <div className="flex justify-between mt-4">
              {/* Download Button */}
              <Download doc={{ output_id: "essay"}} />
              {/* <button
                onClick={handleDownload}
                className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
              >
                Download
              </button> */}
  
              {/* Copy to Clipboard Button */}
              <button
                onClick={handleCopy}
                className="text-gray-500 hover:text-gray-700"
                title="Copy to Clipboard"
              >
                <FaClipboard className="text-xl text-gray-600 hover:text-blue-600" />
              </button>
            </div>
          </div>
        </motion.div>
        
      <Feedback tool={{"name":"Essay Writer"}} />
    

      {!viewMode && (
      <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
      initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        viewport={{ once: true, amount: 0.1 }}>
        <h2 className="text-2xl font-bold mb-4">What is an AI Essay Writer?</h2>
        <p className="text-gray-700 mb-4">
          Our AI-powered Essay Writer helps you generate structured, coherent, and well-written essays based on the topic you provide. Whether you need an essay for school or professional purposes, the tool generates high-quality content with minimal effort.
        </p>
  
        <h3 className="text-xl font-semibold mb-2">Why Use an AI Essay Writer?</h3>
        <table className="min-w-full table-auto border-collapse border border-gray-200 mb-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 border border-gray-200">Benefit</th>
              <th className="px-4 py-2 border border-gray-200">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Time-Saving</td>
              <td className="px-4 py-2 border border-gray-200">
                Quickly generate high-quality essays, saving valuable time for students, professionals, and writers.
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Improved Writing Quality</td>
              <td className="px-4 py-2 border border-gray-200">
                Produce well-structured and coherent essays that enhance your overall writing quality.
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Overcome Writer's Block</td>
              <td className="px-4 py-2 border border-gray-200">
                AI tools help overcome writer’s block by providing creative ideas and essay structures.
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Tailored Content</td>
              <td className="px-4 py-2 border border-gray-200">
                Customize essay length, complexity, and tone to fit academic level or specific requirements.
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Plagiarism-Free</td>
              <td className="px-4 py-2 border border-gray-200">
                Generate unique, plagiarism-free content with advanced AI technology.
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Cost-Effective</td>
              <td className="px-4 py-2 border border-gray-200">
                AI essay writers offer a cost-effective solution to produce high-quality essays without hiring professionals.
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Optimized for SEO</td>
              <td className="px-4 py-2 border border-gray-200">
                Generate content that is optimized for search engines, helping boost your website's rankings.
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">User-Friendly</td>
              <td className="px-4 py-2 border border-gray-200">
                Easy-to-use interface, suitable for both novice and experienced users.
              </td>
            </tr>
          </tbody>
        </table>
  
        <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools?</h3>
        <p className="text-gray-700 mb-4">
          At StudentAITools, we prioritize ease of use, flexibility, and reliability. Our Essay Writer tool is powered by cutting-edge AI, providing accurate and meaningful content that meets your specific needs.
        </p>
        <p className="text-gray-700 mb-4">
        Further advance your writing with our powerful AI essay writer, designed specifically for students seeking to enhance their essays and assignments. Our AI essay writer free tool provides a user-friendly experience, allowing you to generate high-quality essays in minutes without any cost. Tailored to meet the needs of students, this AI essay writer for students offers valuable insights and structure to help you excel in your coursework. Whether you’re facing a tight deadline or looking for inspiration, our AI technology ensures you produce polished, well-researched essays with ease. Start transforming your writing experience today!          
        </p>
  
        <h3 className="text-xl font-semibold mb-2">How to Use Our AI Essay Writer Tool</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
          {/* Step 1: Enter the Essay Topic */}
          <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
            <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
              <FaKeyboard className="text-blue-600" /> {/* Icon for Essay Topic */}
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold text-gray-800">Step 1</h3>
              <p className="text-gray-600">
                Enter your essay topic in the input field.
              </p>
            </div>
          </div>

          {/* Step 2: Select Paragraphs and Education Level */}
          <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
            <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
              <FaListAlt className="text-green-600" /> {/* Icon for Mode Selection */}
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold text-gray-800">Step 2</h3>
              <p className="text-gray-600">
                Select the number of paragraphs and education level.
              </p>
            </div>
          </div>

          {/* Step 3: Click Generate */}
          <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
            <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
              <FaSyncAlt className="text-yellow-600" /> {/* Icon for Generate Button */}
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold text-gray-800">Step 3</h3>
              <p className="text-gray-600">
                Click "Generate" and wait for your essay to be created.
              </p>
            </div>
          </div>

          {/* Step 4: Review and Edit */}
          <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
            <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
              <FaPen className="text-red-600" /> {/* Icon for Review and Edit */}
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold text-gray-800">Step 4</h3>
              <p className="text-gray-600">
                Review and edit the AI-generated content as needed.
              </p>
            </div>
          </div>
        </div>
  
        <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Provide clear, concise topics for the best output.</li>
          <li>Select the appropriate education level to tailor the complexity of your essay.</li>
          <li>Review and customize the generated essay to match your unique voice.</li>
        </ul>
  
        <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This Essay Writer Tool?</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
        {/* Icon Card 1: Students */}
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
            <FaGraduationCap className="text-blue-600" /> {/* Icon for Students */}
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">Students</h3>
            <p className="text-gray-600">
              Ideal for students needing help with <strong>essay writing</strong>. Whether crafting research papers, assignments, or college application essays, our tool generates structured and <strong>plagiarism-free</strong> essays.
            </p>
          </div>
        </div>

        {/* Icon Card 2: Bloggers */}
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
            <FaBlog className="text-green-600" /> {/* Icon for Bloggers */}
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">Bloggers</h3>
            <p className="text-gray-600">
              Perfect for bloggers who need to produce high-quality, engaging blog posts quickly. Generate creative, SEO-optimized content effortlessly with our tool and keep your blog posts fresh and relevant.
            </p>
          </div>
        </div>

        {/* Icon Card 3: Copywriters */}
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
            <FaPenFancy className="text-yellow-600" /> {/* Icon for Copywriters */}
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">Copywriters</h3>
            <p className="text-gray-600">
              Copywriters can use this tool to craft compelling sales copy, email content, or product descriptions that captivate readers. Achieve high-converting, professional-grade content with ease.
            </p>
          </div>
        </div>

        {/* Icon Card 4: Researchers */}
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
            <FaSearch className="text-red-600" /> {/* Icon for Researchers */}
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-800">Researchers</h3>
            <p className="text-gray-600">
              Researchers can quickly generate <strong>summaries</strong> or structured overviews of their material, making the tool perfect for speeding up the <strong>research process</strong> and organizing findings.
            </p>
          </div>
        </div>

      </div>

  
        <h3 className="text-xl font-semibold mb-2">Best Free Essay Writer Tools</h3>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li><strong>Jasper AI:</strong> A versatile AI tool that generates high-quality essays, blog posts, and creative content.</li>
          <li><strong>Writesonic:</strong> Known for its ability to produce SEO-optimized essays, articles, and social media content.</li>
          <li><strong>Copy.ai:</strong> A powerful AI platform that helps writers craft engaging essays, emails, and product descriptions quickly.</li>
          <li><strong>Grammarly:</strong> Primarily a grammar-checker, but also offers paraphrasing and sentence restructuring suggestions for essays.</li>
          <li><strong>Quillbot:</strong> A paraphrasing tool that assists in rewriting and improving essay content for better clarity and originality.</li>
        </ul>
  
        <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
        <p className="text-gray-700">
          Our AI Essay Writer aims for high accuracy. However, achieving perfection is still challenging. Please note: the predictions are for demonstration purposes only. Do not rely on them for real-world applications. Use caution, as we are not liable for any damages incurred. We strongly recommend using human oversight when using our products.
        </p>
      </motion.div>
      )}
      
      <NewsletterCard/>
      
      {user && (
        <BuyCreditsButton />
      )}
    </div>

    {!viewMode && (
     <Footer />
    )}

    <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />

    </>
  );
  
};

export default EssayWriter;