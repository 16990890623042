import React, { useState } from 'react';
import { getDatabase, ref, set, push } from "firebase/database";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { motion } from 'framer-motion';

const NewsletterCard = () => {
  const [email, setEmail] = useState(''); // Email input state
  const [subscribed, setSubscribed] = useState(false); // Track if subscribed
  const [loading, setLoading] = useState(false); // Track loading state
  const database = getDatabase(); // Firebase DB

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the API call starts

    try {
      // Get the reCAPTCHA token
      ReactRecaptcha3.getToken().then(
        async (recaptchaToken) => {
          console.log(recaptchaToken);

            const response = await axios.post('https://app.studentaitools.com/api/send-email', {
                email: email,
                subject: 'Welcome to StudentAITools',
                type: 'newsletter',
                recaptchaToken, // Send the reCAPTCHA token to the server for verification
            });
            if (response.status === 200) {
                setSubscribed(true);
                toast.success(`Thank you for subscribing to our Newsletter!`);
            }
            setLoading(false); // Set loading to false after API call completes
        },
        (error) => {
          console.log(error);
          setLoading(false); // Set loading to false if there is an error
        }
      );
    } catch (error) {
      toast.error("Failed to subscribe. Please try again.");
      setLoading(false); // Set loading to false if an exception occurs
    }
  };

  return (
    <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 my-8" 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, ease: 'easeOut' }}
    viewport={{ once: true, amount: 0.1 }} >
      <h2 className="text-2xl font-semibold text-center mb-4">Subscribe to our Newsletter</h2>
      <p className="text-gray-600 text-center mb-6">
      Stay ahead with our AI-powered tools for students and teachers. Enhance your learning and teaching!
      </p>
      <form onSubmit={handleSubscribe}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border p-2 rounded w-full mb-4"
          placeholder="Enter your email"
          required
        />
        <button
          type="submit"
          className="w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition duration-300"
          disabled={loading} // Disable the button while loading
        >
          {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Subscribing...</span>
                    </div>
                  ) : "Subscribe"}
        </button>
      </form>
    </motion.div>
  );
};

export default NewsletterCard;