import React from 'react';
import { Helmet } from 'react-helmet';
import { FaTwitter, FaInstagram, FaYoutube, FaFacebookF, FaLinkedinIn, FaWhatsapp, FaCopy, FaPinterest, FaRedditAlien } from 'react-icons/fa';
import FAQComponent from './FAQComponent';
import Footer from '../Footer';

const BlogLayout = ({ title, description, blogData, faqData, date, author, blogImage, blogImageAlt, sections, blogIntro }) => {
  const blogUrl = window.location.href;

  // Function to copy blog URL to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(blogUrl);
    alert("Blog URL copied to clipboard!");
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
        {/* Static Share Buttons */}
        <div className="fixed left top-1/3 z-50 hidden md:flex flex-col">
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#1877F2] text-white p-4 hover:opacity-80">
            <FaFacebookF />
            </button>
        </a>
        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#0A66C2] text-white p-4 hover:opacity-80">
            <FaLinkedinIn />
            </button>
        </a>
        <a href={`https://x.com/intent/tweet?url=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#1DA1F2] text-white p-4 hover:opacity-80">
            <FaTwitter />
            </button>
        </a>
        <a href={`https://wa.me/?text=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#25D366] text-white p-4 hover:opacity-80">
            <FaWhatsapp />
            </button>
        </a>
        <a href={`https://pinterest.com/pin/create/button/?url=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#E60023] text-white p-4 hover:opacity-80">
            <FaPinterest />
            </button>
        </a>
        <a href={`https://reddit.com/submit?url=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#FF4500] text-white p-4 hover:opacity-80">
            <FaRedditAlien />
            </button>
        </a>
        <button onClick={copyToClipboard} className="bg-gray-600 text-white p-4 hover:opacity-80">
            <FaCopy />
        </button>
        </div>

      <div className="bg-gray-100 py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
            {title}
          </h1>
          <div className="text-center text-gray-600 mb-6">
            <p>By {author} | {date}</p>
          </div>

          {/* Social Media Icons */}
          <div className="flex justify-center space-x-4 mb-4">
            <a href="https://x.com/schoolaitools" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="text-blue-500 text-2xl hover:text-blue-700" />
            </a>
            <a href="https://www.instagram.com/studentai_tools/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-pink-500 text-2xl hover:text-pink-700" />
            </a>
            <a href="https://www.youtube.com/@studentaitools" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="text-red-500 text-2xl hover:text-red-700" />
            </a>
          </div>

          {/* Blog Image */}
          <div className="flex justify-center mb-8">
            <img
              src={blogImage}
              alt={blogImageAlt}
              className="rounded-lg"
              style={{ width: '100%', maxHeight: '500px', objectFit: 'cover', maxWidth: '100%' }}
            />
          </div>

          {/* Blog Introduction */}
          {blogIntro && blogIntro.length > 0 && blogIntro.map((intro, index) => (
                <div key={index} className="mb-6">
                    <p className="text-gray-600">{intro}</p>
                </div>
           ))}


          {/* Interactive Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
        {blogData.cards.map((tool, index) => (
            <div
            key={index}
            className={`p-6 rounded-lg shadow-lg transition-shadow duration-300 cursor-pointer ${
                tool.isStudentTool
                ? 'bg-green-500 text-white border-blue-500 relative'
                : 'bg-white text-gray-800 hover:border-blue-500 hover:shadow-2xl'
            } border-2`}
            >
            {tool.isStudentTool && (
                <span className="absolute top-0 right-0 bg-white text-gray-800 py-1 px-2 rounded-lg">
                {tool.badge}
                </span>
            )}
            <h3 className="text-xl font-semibold mb-3">{tool.title}</h3>
            <p>{tool.description}</p>
            
            {/* Buttons for Visit/Get Discount */}
            <div className="mt-4">
                {tool.isStudentTool ? (
                <button
                    onClick={() => blogData.setShowPopup(true)}
                    className="bg-white text-blue-800 py-2 px-4 rounded hover:bg-white transition duration-300"
                >
                    Get Discount
                </button>
                ) : (
                <a
                    href={tool.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
                >
                    Visit
                </a>
                )}
            </div>
            </div>
        ))}
        </div>

          {/* Popup for Discount */}
          {blogData.showPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="relative bg-white p-8 rounded-lg shadow-lg text-center">
                <button
                  onClick={() => blogData.setShowPopup(false)}
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  &times;
                </button>

                <h2 className="text-2xl font-bold mb-4">Special Offer for You!</h2>
                <p className="text-lg mb-6">
                  Use code <span className="border-dotted border-2 border-gray-500 px-2 py-1">DISCOUNT2024</span> for 20% OFF on StudentAITools.
                </p>
                <button
                  onClick={() => {
                    blogData.setShowPopup(false);
                    blogData.navigate("/"); // Navigate to the homepage
                  }}
                  className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
                >
                  Try Now
                </button>
              </div>
            </div>
          )}

          
        {/* Dynamic Sections */}
        {sections && sections.length > 0 && sections.map((section, index) => (
        <div key={index} className="mb-12">
            {section.section_heading && (
                <h2 className="text-3xl font-semibold text-gray-800 mb-4">{section.section_heading}</h2>
            )}

            {/* Conditionally render image if section_img exists */}
            {section.section_img && (
            <div className="flex justify-center mb-4">
                <img
                src={section.section_img}
                alt={section.section_img_alt}
                className="rounded-lg"
                style={{ width: '100%', maxHeight: '400px', objectFit: 'cover', maxWidth: '100%' }}
                />
            </div>
            )}
            
            {/* Section Content */}
            {section.section_content && (
                <p className="text-gray-600">{section.section_content}</p>
            )}

            {/* Conditionally render list if section_list exists */}
            {section.section_list && section.section_list.length > 0 && (
            <ul className="list-disc pl-5 text-gray-600 mt-4">
                {section.section_list.map((item, idx) => (
                <li key={idx}>{item}</li>
                ))}
            </ul>
            )}
        </div>
        ))}

          {/* Interactive Table */}
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">AI Tools Summary Table</h2>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200 text-left text-gray-800 font-semibold">
                  AI Tool
                </th>
                <th className="py-2 px-4 border-b border-gray-200 text-left text-gray-800 font-semibold">
                  Best For
                </th>
                <th className="py-2 px-4 border-b border-gray-200 text-left text-gray-800 font-semibold">
                  Free Version
                </th>
              </tr>
            </thead>
            <tbody>
              {blogData.cards.map((tool, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b border-gray-200 text-gray-800">{tool.title}</td>
                  <td className="py-2 px-4 border-b border-gray-200 text-gray-600">
                    {tool.bestFor}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-200 text-gray-600">{tool.free_version}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <FAQComponent faqData={faqData} />

      <div className="my-8 text-center md:hidden"> {/* Hide in desktop view */}
        <p className="text-lg font-semibold mb-4">If You Enjoyed This Article, Please Share It - This Motivates Us:</p>
        <div className="flex flex-row justify-center space-x-4"> {/* Horizontal alignment on mobile view */}
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#1877F2] text-white p-4 hover:opacity-80">
                <FaFacebookF />
            </button>
            </a>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#0A66C2] text-white p-4 hover:opacity-80">
                <FaLinkedinIn />
            </button>
            </a>
            <a href={`https://x.com/intent/tweet?url=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#1DA1F2] text-white p-4 hover:opacity-80">
                <FaTwitter />
            </button>
            </a>
            <a href={`https://wa.me/?text=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#25D366] text-white p-4 hover:opacity-80">
                <FaWhatsapp />
            </button>
            </a>
            <a href={`https://pinterest.com/pin/create/button/?url=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#E60023] text-white p-4 hover:opacity-80">
                <FaPinterest />
            </button>
            </a>
            <a href={`https://reddit.com/submit?url=${blogUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-[#FF4500] text-white p-4 hover:opacity-80">
                <FaRedditAlien />
            </button>
            </a>
            <button onClick={copyToClipboard} className="bg-gray-600 text-white p-4 hover:opacity-80">
            <FaCopy />
            </button>
        </div>
        </div>

      <Footer />
    </>
  );
};

export default BlogLayout;
