import React, { useState, useEffect, useRef  } from "react";  
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { FaFileAlt, FaHtml5, FaFileWord, FaFilePdf, FaPrint } from 'react-icons/fa';
import * as docx from 'docx';
import { PDFDocument, rgb } from 'pdf-lib';

const Download = ({doc}) => {
    const [showDropdown , setShowDropdown] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For tools dropdown
    const dropdownRef = useRef(null); // Reference for the dropdown

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false); // Close the dropdown
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [dropdownRef]);

      const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };
    const handleDownload = async (fileType) => {
        let id = doc.output_id;  //document id that needs to be printed

        if (!document) {
            toast.error("No content to download!");
            return;
        }
        const elementToDownload = document.getElementById(id).textContent;

        const fileName = "generated-doc";
        let blob = new Blob([elementToDownload], { type: "text/plain" });

        if (fileType === 'txt') {
            saveAs(blob, `${fileName}.txt`);
        } else if (fileType === 'html') {
            saveAs(blob, `${fileName}.html`);
        } else if (fileType === 'docx') {
            const doc = new docx.Document({
            sections: [{
                properties: {},
                children: [new docx.Paragraph({ text: elementToDownload })],
              }],
            });
            const buffer = await docx.Packer.toBlob(doc);
            saveAs(buffer, `${fileName}.docx`);
        }
    }
    const handlePrint = async () => {
      let id = doc.output_id;
      const elementToPrint = document.getElementById(id);
      if (elementToPrint) {
          // Create a new window for printing
          const printWindow = window.open('', '_blank');
          printWindow.document.write(`
              <html>
                  <head><title>Print Document</title></head>
                  <body>${elementToPrint.innerHTML}</body>
              </html>
          `);
          printWindow.document.close();
          printWindow.focus();
          // Trigger the print command
          printWindow.print();
          printWindow.close();
      } else {
          toast.error("Element not found for printing!");
      }
    }
    return (
        <div className="relative inline-block">
        <button
          onClick={toggleDropdown}
          className="btn btn-neutral mr-4"
        >
          Download
        </button>
        <button 
          onClick={handlePrint}
          className="btn btn-outline"
        >
          <FaPrint className="mr-2" /> Print
        </button>

        
        {isDropdownOpen && (
          <div className="absolute lg:right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg" ref={dropdownRef} style={{zIndex: 999}}>
            <button
              onClick={() => handleDownload('txt')}
              className="flex items-center w-full px-4 py-2 text-gray-800 hover:bg-gray-100"
            >
              <FaFileAlt className="mr-2 text-gray-600" /> TXT
            </button>
            <button
              onClick={() => handleDownload('html')}
              className="flex items-center w-full px-4 py-2 text-gray-800 hover:bg-gray-100"
            >
              <FaHtml5 className="mr-2 text-orange-600" /> HTML
            </button>
            {/* <button
              onClick={() => handleDownload('docx')}
              className="flex items-center w-full px-4 py-2 text-gray-800 hover:bg-gray-100"
            >
              <FaFileWord className="mr-2 text-blue-600" /> DOCX
            </button> */}
          </div>
        )}
      </div>
      
    );
};

export default Download;
