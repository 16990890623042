import React, { useState, useEffect } from "react";
import { generateQuiz } from "../openaiService"; // OpenAI service for quiz generation
import { getDatabase, ref, push, set, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BuyCreditsButton from './BuyCreditsButton'; // Import the new BuyCreditsButton component
import Select from 'react-select';
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import { Helmet } from 'react-helmet';
import { FaChalkboardTeacher, FaGraduationCap, FaUserGraduate, FaSearch, FaKeyboard, FaListAlt, FaSyncAlt, FaPen, FaClipboardList, FaQuestionCircle } from "react-icons/fa";
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';
import { Link, useNavigate, useLocation } from "react-router-dom";

const MAX_QUESTIONS = 100; // Maximum number of questions
var QUESTIONS_PER_CREDIT = 10; // 1 credit for every 10 questions

// Mock data for grades, subjects, and topics
const gradesData = {
  "Pre-K": {
    subjects: {
      Math: [
        "Counting",
        "Basic Shapes",
        "Patterns",
        "Sorting",
        "Number Recognition",
        "Comparing Quantities",
        "Measuring",
        "Simple Addition and Subtraction",
        "Positional Words (e.g., above, below)"
      ],
      'Language arts': [
        "Alphabet",
        "Rhyming Words",
        "Listening Comprehension",
        "Story Time",
        "Letter Recognition",
        "Phonological Awareness",
        "Beginning Sounds",
        "Sight Words",
        "Pre-Writing Skills (Tracing and Scribbling)"
      ],
      Science: [
        "Nature",
        "Plants",
        "Animals",
        "Weather",
        "Seasons",
        "Senses (Touch, Smell, Sight, Hearing, Taste)",
        "Day and Night",
        "Habitats",
        "Sink or Float",
        "Magnets"
      ],
      'Social Studies': [
        "Community Helpers",
        "Family and Friends",
        "Rules and Responsibility",
        "Feelings and Emotions",
        "Holidays and Celebrations",
        "Neighborhoods",
        "Maps and Directions",
        "Diversity and Inclusion"
      ]
    }
  },
  "Kindergarten": {
      subjects: {
        Math: [
          "Counting to 100",
          "Basic Addition and Subtraction",
          "Identifying and Writing Numbers",
          "Patterns and Sequences",
          "Shapes and Geometry",
          "Measurement (Length, Weight, Volume)",
          "Comparing Quantities (Greater Than, Less Than)",
          "Number Bonds",
          "Place Value (Ones and Tens)",
          "Time (Reading Clocks)",
          "Money (Identifying Coins and Bills)"
        ],
        'Language arts': [
          "Alphabet Mastery",
          "Phonemic Awareness",
          "Phonics and Decoding",
          "Sight Words",
          "Rhyming Words",
          "Blending Sounds",
          "Beginning and Ending Sounds",
          "Simple Sentences",
          "Story Comprehension",
          "Pre-Writing Skills (Tracing, Letter Formation)",
          "Beginning Reading"
        ],
        Science: [
          "Living and Non-living Things",
          "Plants and Animals",
          "Weather and Seasons",
          "The Five Senses",
          "Basic Earth Science (Rocks, Soil, Water)",
          "Magnets and Force",
          "Simple Experiments",
          "Health and Hygiene",
          "Habitats (Forests, Oceans, Deserts)",
          "Life Cycles (Butterflies, Frogs)"
        ],
        'Social Studies': [
          "Community Helpers",
          "Rules and Responsibility",
          "Family and Friends",
          "Basic Geography (Maps, Globes)",
          "Holidays and Traditions",
          "Cultural Awareness",
          "Famous Historical Figures",
          "American Symbols (Flag, Statue of Liberty)",
          "Citizenship",
          "Neighborhoods and Communities"
        ]
      }
    },
  "1st Grade": {
      subjects: {
        Math: [
          "Addition and Subtraction within 20", "Counting to 120", "Place Value (Ones, Tens, Hundreds)", "Comparing Numbers (Greater Than, Less Than, Equal To)", "Basic Fractions (Halves, Quarters)", "Word Problems", "Shapes and Geometry", "Measurement (Length, Weight, Volume)", "Telling Time (Hours and Half-Hours)", "Money (Identifying Coins, Simple Transactions)", "Skip Counting by 2s, 5s, 10s"
        ],
        'Language arts': [
          "Phonics and Decoding", "Sight Words Mastery", "Blending and Segmenting Sounds", "Story Comprehension", "Reading Simple Chapter Books", "Rhyming and Poetry", "Identifying Main Ideas and Supporting Details", "Beginning, Middle, and End of Stories", "Simple Sentences and Sentence Structure", "Writing Complete Sentences", "Basic Grammar (Capitalization, Punctuation)", "Spelling Practice"
        ],
        Science: [
          "Animals and Their Habitats", "Plants and Their Life Cycles", "Weather and Seasons", "The Water Cycle", "The Five Senses", "Materials and Properties (Solids, Liquids, Gases)", "Simple Machines", "Space and Planets", "Health and Nutrition", "Earth Science (Rocks, Soil, Water)", "Basic Experiments and Observations"
        ],
        'Social Studies': [
          "Family and Community", "Maps and Globes", "American Symbols (Flag, Eagle, Statue of Liberty)", "Famous Americans (George Washington, Martin Luther King Jr.)", "Rules and Laws", "Good Citizenship", "Holidays and Traditions", "Diversity and Cultures", "Past, Present, Future (History)", "Jobs in the Community", "Basic Geography (Landforms, Continents, Oceans)"
        ]
      }
    },
  "2nd Grade": {
    "subjects": {
      "Math": [
        "Addition and Subtraction within 100", "Place Value (Ones, Tens, Hundreds, Thousands)", "Skip Counting by 2s, 5s, 10s, 100s", "Basic Multiplication and Division", "Measurement (Length, Weight, Volume)", "Time (Telling Time to the Nearest 5 Minutes)", "Money (Solving Word Problems Involving Dollars and Cents)", "Basic Fractions (Halves, Thirds, Quarters)", "Shapes and Geometry", "Word Problems", "Comparing and Ordering Numbers"
      ],
      "Language arts": [
        "Reading Fluency", "Comprehension Strategies", "Main Idea and Supporting Details", "Retelling Stories", "Character, Setting, and Plot", "Context Clues for Vocabulary", "Phonics and Decoding", "Writing Complete Sentences", "Paragraph Writing", "Basic Grammar (Capitalization, Punctuation, Nouns, Verbs)", "Writing Personal Narratives", "Spelling Patterns"
      ],
      "Science": [
        "States of Matter (Solids, Liquids, Gases)", "Life Cycles of Plants and Animals", "Habitats and Ecosystems", "Weather Patterns", "Earth Materials (Rocks, Soil, Water)", "Force and Motion", "Simple Machines", "Energy (Heat, Light, Sound)", "The Water Cycle", "Health and Nutrition", "Basic Scientific Method (Observations, Experiments)"
      ],
      "Social Studies": [
        "Communities and Neighborhoods", "Maps and Globes", "Continents and Oceans", "Famous Americans (Abraham Lincoln, Harriet Tubman)", "Rules and Laws", "Citizenship and Responsibility", "Cultural Traditions", "American Symbols (Flag, Bald Eagle)", "Holidays and Celebrations", "Local Government", "Economy (Needs vs. Wants)"
      ]
    }
  },
  "3rd Grade": {
    "subjects": {
      "Math": [
        "Addition and Subtraction within 1,000", "Multiplication and Division Facts (up to 10x10)", "Place Value (Ones, Tens, Hundreds, Thousands)", "Fractions (Halves, Thirds, Quarters, and Eighths)", "Measurement (Length, Weight, Volume)", "Time (Telling Time to the Nearest Minute)", "Money (Making Change, Solving Word Problems)", "Area and Perimeter", "Geometry (Identifying Shapes, Lines, Angles)", "Multiplication and Division Word Problems", "Comparing and Ordering Numbers", "Skip Counting and Patterns"
      ],
      "Language arts": [
        "Reading Fluency and Accuracy", "Comprehension Strategies (Summarizing, Predicting)", "Main Idea and Supporting Details", "Character, Setting, and Plot Development", "Context Clues for Vocabulary", "Fact vs. Opinion", "Cause and Effect Relationships", "Comparing and Contrasting Texts", "Writing Complete Paragraphs", "Narrative Writing", "Opinion Writing", "Basic Grammar (Pronouns, Adjectives, Adverbs)", "Spelling Patterns and Rules"
      ],
      "Science": [
        "Ecosystems and Food Chains", "Plants and Their Life Cycles", "Animals and Their Habitats", "States of Matter (Solids, Liquids, Gases)", "Weather and Climate", "Earth's Resources (Rocks, Minerals, Water)", "Force, Motion, and Energy", "The Solar System", "The Water Cycle", "Simple Machines", "The Scientific Method (Hypothesis, Observation, Conclusion)", "Health and Nutrition"
      ],
      "Social Studies": [
        "Geography (Continents, Oceans, Map Skills)", "Native American Cultures", "Early American Settlers", "Communities and Government", "Famous Americans (Benjamin Franklin, Rosa Parks)", "Rules and Laws", "The Branches of Government", "Cultural Traditions and Holidays", "Economics (Goods, Services, Producers, Consumers)", "Explorers and Exploration", "American Symbols and Landmarks", "Citizenship and Responsibility"
      ]
    }
  },
  "4th Grade": {
  "subjects": {
    "Math": [
      "Multi-Digit Addition and Subtraction", "Multiplication and Division with Larger Numbers", "Place Value (Up to Millions)", "Fractions (Equivalent Fractions, Adding and Subtracting)", "Decimals (Tenths, Hundredths)", "Measurement (Length, Weight, Volume)", "Time (Elapsed Time, Word Problems)", "Money (Making Change, Word Problems)", "Area, Perimeter, and Volume", "Geometry (Lines, Angles, Symmetry)", "Factors and Multiples", "Multiplication and Division Word Problems", "Rounding and Estimation", "Data and Graphs (Bar Graphs, Line Plots)"
    ],
    "Language arts": [
      "Reading Fluency and Comprehension", "Main Idea and Supporting Details", "Summarizing Text", "Character, Setting, and Plot", "Context Clues for Vocabulary", "Compare and Contrast", "Fact and Opinion", "Making Inferences and Drawing Conclusions", "Writing Complete Essays", "Opinion Writing", "Narrative Writing", "Informational Writing", "Basic Grammar (Nouns, Verbs, Adjectives, Adverbs)", "Spelling Patterns", "Punctuation and Capitalization"
    ],
    "Science": [
      "Ecosystems and Food Webs", "Plant and Animal Adaptations", "Earth's Resources (Rocks, Minerals, Fossils)", "Weather and Climate", "Force, Motion, and Energy", "The Water Cycle", "Electricity and Magnetism", "The Solar System", "Simple Machines", "The Scientific Method (Hypothesis, Experiments, Data)", "Health and Nutrition", "Environmental Science and Conservation"
    ],
    "Social Studies": [
      "Geography (Maps, Landforms, Continents, Oceans)", "Native American History and Culture", "Exploration and Early Colonization", "The American Revolution", "Branches of Government", "The Constitution and Bill of Rights", "Civics and Citizenship", "Economics (Goods, Services, Trade)", "State History and Symbols", "Famous Americans (Historical Figures)", "Cultural Diversity", "Holidays and Traditions"
    ]
  }
},
  "5th Grade": {
  "subjects": {
    "Math": [
      "Addition and Subtraction of Fractions", "Multiplication and Division of Fractions", "Decimals (Place Value, Addition, Subtraction, Multiplication, Division)", "Multiplying and Dividing Whole Numbers", "Place Value (Up to Billions)", "Volume and Surface Area", "Measurement (Conversions)", "Geometry (Lines, Angles, Symmetry)", "Coordinate Planes", "Interpreting Data (Graphs, Charts)", "Order of Operations (PEMDAS)", "Word Problems", "Multiplication and Division with Larger Numbers"
    ],
    "Language arts": [
      "Reading Fluency and Comprehension", "Main Idea and Supporting Details", "Summarizing Text", "Character, Setting, and Plot Development", "Theme and Moral of the Story", "Compare and Contrast", "Inferences and Drawing Conclusions", "Point of View", "Text Structure (Cause and Effect, Problem and Solution)", "Writing Multi-Paragraph Essays", "Narrative Writing", "Opinion Writing", "Informational Writing", "Grammar (Parts of Speech, Tenses)", "Spelling and Vocabulary", "Punctuation and Capitalization"
    ],
    "Science": [
      "Ecosystems and Food Chains", "Plant and Animal Adaptations", "Earth's Resources and Conservation", "The Water Cycle", "Weather and Climate", "Force and Motion", "Energy (Kinetic, Potential, Renewable, Non-Renewable)", "Electricity and Magnetism", "The Solar System", "Space Exploration", "The Scientific Method (Experiments, Data Analysis)", "Human Body Systems", "Health and Nutrition", "Environmental Science"
    ],
    "Social Studies": [
      "Early American History (Exploration, Colonization)", "The American Revolution", "The Civil War", "The Constitution and Government", "Geography (Continents, Oceans, Landforms)", "Economics (Supply, Demand, Goods, Services)", "Civics and Citizenship", "Westward Expansion", "Industrial Revolution", "World War I and II", "Famous Historical Figures", "State and National Government", "Cultural Diversity and Traditions"
    ]
  }
},
  "6th Grade": {
  "subjects": {
    "Math": [
      "Ratios and Proportions", "Decimals (Operations and Place Value)", "Fractions (Operations and Simplification)", "Percentages", "Expressions and Equations", "Geometry (Angles, Area, Volume)", "Integers and Rational Numbers", "Coordinate Planes and Graphing", "Statistical Analysis (Mean, Median, Mode)", "Data Interpretation (Graphs, Charts, Tables)", "Prime Factorization", "Order of Operations (PEMDAS)", "Word Problems and Critical Thinking"
    ],
    "Language arts": [
      "Reading Comprehension (Fiction and Non-Fiction)", "Theme and Main Idea", "Supporting Details and Evidence", "Character Development and Analysis", "Compare and Contrast", "Point of View and Perspective", "Figurative Language and Literary Devices", "Cause and Effect", "Summarizing and Paraphrasing", "Text Structure (Problem and Solution, Cause and Effect)", "Writing Structured Essays", "Narrative Writing", "Informative Writing", "Persuasive Writing", "Grammar (Sentence Structure, Tenses, Parts of Speech)", "Spelling and Vocabulary Development"
    ],
    "Science": [
      "Scientific Method (Hypothesis, Experiment, Conclusion)", "Earth's Structure and Plate Tectonics", "Weather and Climate", "Water Cycle and Water Resources", "Ecosystems and Biodiversity", "Energy Forms and Conservation", "Force and Motion", "Electricity and Magnetism", "Astronomy and Space Exploration", "Human Body Systems", "Cells and Microorganisms", "Environmental Science and Conservation", "Health and Nutrition"
    ],
    "Social Studies": [
      "Ancient Civilizations (Mesopotamia, Egypt, Greece, Rome)", "World Geography (Continents, Oceans, Countries)", "Cultural Anthropology", "Governments and Political Systems", "Economic Systems (Supply and Demand, Trade)", "World Religions", "Exploration and Colonization", "The Middle Ages", "Renaissance and Reformation", "Human Rights and Citizenship", "Geopolitical Conflicts and Resolutions", "Current Events and Global Issues"
    ]
  }
},
  "7th Grade": {
  "subjects": {
    "Math": [
      "Ratios and Proportional Relationships", "Operations with Rational Numbers", "Expressions and Equations", "Solving Multi-Step Equations and Inequalities", "Geometry (Area, Surface Area, Volume)", "Percents (Calculations and Applications)", "Probability and Statistics", "Linear Equations and Graphing", "Data Interpretation (Charts, Graphs, Tables)", "Scientific Notation", "Square Roots and Exponents", "Word Problems and Real-World Applications"
    ],
    "Language arts": [
      "Reading Comprehension (Fiction and Non-Fiction)", "Analyzing Theme and Central Idea", "Character Development and Relationships", "Figurative Language and Literary Devices", "Text Structure (Compare and Contrast, Problem and Solution)", "Making Inferences and Drawing Conclusions", "Summarizing and Paraphrasing", "Analyzing Point of View and Perspective", "Writing Analytical Essays", "Argumentative Writing", "Expository Writing", "Narrative Writing", "Grammar (Complex Sentences, Clauses, Verb Tenses)", "Spelling and Vocabulary Building"
    ],
    "Science": [
      "Scientific Method and Experimental Design", "Cells and Cellular Functions", "Genetics and Heredity", "Ecology and Ecosystems", "Human Body Systems (Circulatory, Respiratory, Digestive)", "Weather and Atmosphere", "Earth Science (Geology, Rock Cycle, Plate Tectonics)", "Energy (Forms, Transfer, Conservation)", "Chemical Reactions and Properties of Matter", "Motion and Forces", "Astronomy and the Solar System", "Environmental Science and Sustainability", "Health and Nutrition"
    ],
    "Social Studies": [
      "World Geography and Mapping Skills", "Ancient Civilizations (Greece, Rome, China)", "Feudalism and the Middle Ages", "The Renaissance and Reformation", "Exploration and the Age of Discovery", "Colonization and Empire Building", "Revolutions (American, French, Industrial)", "World Wars I and II", "Cold War and Modern Conflicts", "Government Systems (Democracy, Monarchy, Communism)", "Economics (Supply and Demand, Global Trade)", "Current Events and Global Issues"
    ]
  }
},
  "8th Grade": {
  "subjects": {
    "Math": [
      "Linear Equations and Inequalities", "Functions and Their Representations", "Systems of Equations", "Pythagorean Theorem", "Exponents and Scientific Notation", "Rational and Irrational Numbers", "Geometry (Transformations, Congruence, Similarity)", "Volume of Cylinders, Cones, and Spheres", "Data Analysis and Probability", "Slope and Rate of Change", "Graphing Linear Functions", "Word Problems and Real-World Applications", "Quadratic Equations", "Polynomials and Factoring"
    ],
    "Language arts": [
      "Reading Comprehension (Fiction, Non-Fiction, Informational Texts)", "Analyzing Theme and Central Idea", "Character and Plot Development", "Author's Purpose and Perspective", "Figurative Language and Literary Devices", "Summarizing and Paraphrasing", "Analyzing Text Structure (Cause and Effect, Problem and Solution)", "Making Inferences and Drawing Conclusions", "Writing Literary Analysis Essays", "Argumentative Writing", "Expository Writing", "Narrative Writing", "Grammar (Complex Sentences, Punctuation, Verb Moods)", "Spelling and Vocabulary Building"
    ],
    "Science": [
      "Scientific Method and Experimentation", "Atomic Structure and Periodic Table", "Chemical Reactions and Equations", "Genetics and Heredity", "Ecology and Environmental Science", "Forces and Motion", "Energy (Kinetic, Potential, Thermal)", "Waves (Sound, Light, Electromagnetic)", "The Solar System and Universe", "Earth's History (Geology, Fossils, Rock Cycle)", "Human Body Systems (Nervous, Endocrine, Reproductive)", "Weather and Climate", "Health and Nutrition"
    ],
    "Social Studies": [
      "American History (Colonial Period to Reconstruction)", "The Constitution and Bill of Rights", "Civics and Government Structure", "The Civil War and Its Impact", "Industrialization and Urbanization", "Westward Expansion", "World Geography and Mapping Skills", "World War I and II", "Cold War and Modern Conflicts", "Civil Rights Movements", "Economic Systems (Capitalism, Socialism, Communism)", "Current Events and Global Issues"
    ]
  }
},
  "9th Grade": {
  "subjects": {
    "Math": [
      "Algebra I", "Linear Equations and Inequalities", "Polynomials and Factoring", "Quadratic Equations", "Functions (Linear, Quadratic)", "Exponents and Radicals", "Rational Expressions and Equations", "Graphing Linear and Quadratic Functions", "Solving Systems of Equations", "Word Problems and Real-World Applications"
    ],
    "Science": [
      "Biology", "Cell Structure and Function", "Genetics and Heredity", "Evolution and Natural Selection", "Ecology and Ecosystems", "Photosynthesis and Cellular Respiration", "Human Body Systems", "Classification of Living Organisms", "Environmental Science", "Scientific Method and Experimentation"
    ],
    "Language arts": [
      "Reading Comprehension (Literature, Non-Fiction)", "Analyzing Themes and Motifs", "Character Analysis", "Plot Structure and Development", "Figurative Language and Literary Devices", "Writing Analytical Essays", "Narrative Writing", "Argumentative Writing", "Grammar (Sentence Structure, Punctuation, Verb Tenses)", "Vocabulary Development", "Public Speaking and Presentations"
    ],
    "Social Studies": [
      "World History", "Ancient Civilizations (Mesopotamia, Egypt, Greece, Rome)", "Medieval Europe", "The Renaissance and Reformation", "Exploration and Colonization", "The Industrial Revolution", "World Wars I and II", "Cold War and Modern Conflicts", "Geography (Continents, Countries, Landforms)", "Civics and Government Systems"
    ]
  }
},
  "10th Grade": {
  "subjects": {
    "Math": [
      "Geometry", "Properties of Triangles", "Congruence and Similarity", "Pythagorean Theorem", "Circles (Arcs, Chords, Tangents)", "Coordinate Geometry", "Transformations", "Surface Area and Volume", "Trigonometry (Basic Functions)", "Proofs and Logic", "Quadratic Equations", "Graphing Functions", "Word Problems and Real-World Applications"
    ],
    "Science": [
      "Chemistry", "Atomic Structure", "Periodic Table", "Chemical Bonds and Reactions", "Stoichiometry", "Acids and Bases", "Thermodynamics", "Gas Laws", "Chemical Equilibrium", "Organic Chemistry (Basics)", "Environmental Chemistry", "Lab Safety and Experimentation"
    ],
    "Language arts": [
      "Literary Analysis (Fiction, Non-Fiction, Poetry)", "Theme and Motif Development", "Characterization and Plot Structure", "Rhetorical Devices and Persuasive Writing", "Narrative Writing", "Argumentative Writing", "Research Papers", "Grammar (Sentence Structure, Clauses, Verb Tenses)", "Vocabulary Development", "Public Speaking and Oral Presentations"
    ],
    "Social Studies": [
      "US History", "The American Revolution", "The Constitution and Bill of Rights", "Westward Expansion", "The Civil War and Reconstruction", "Industrialization and Urbanization", "The Great Depression", "World Wars I and II", "The Cold War", "Civil Rights Movements", "Modern US Politics", "Current Events"
    ]
  }
},
  "11th Grade": {
  "subjects": {
    "Math": [
      "Algebra II", "Quadratic Functions and Equations", "Polynomials and Rational Expressions", "Exponential and Logarithmic Functions", "Complex Numbers", "Systems of Equations and Inequalities", "Sequences and Series", "Probability and Statistics", "Trigonometry (Advanced Functions)", "Matrices", "Graphing Polynomial and Rational Functions", "Word Problems and Real-World Applications"
    ],
    "Science": [
      "Physics", "Motion and Forces", "Newton’s Laws", "Energy (Kinetic, Potential, Thermal)", "Waves and Sound", "Light and Optics", "Electricity and Magnetism", "Thermodynamics", "Modern Physics (Quantum, Relativity)", "Mechanical and Electromagnetic Waves", "Lab Experiments and Data Analysis", "Scientific Method"
    ],
    "Language arts": [
      "American Literature", "Analyzing Themes and Symbolism", "Characterization and Plot Development", "Rhetorical Analysis of Speeches and Essays", "Research Papers", "Narrative Writing", "Argumentative and Persuasive Writing", "Poetry Analysis", "Grammar (Advanced Sentence Structure, Syntax)", "Vocabulary Development", "Public Speaking and Debate"
    ],
    "Social Studies": [
      "World History", "Renaissance and Reformation", "Age of Exploration", "Industrial Revolution", "World War I", "World War II", "The Cold War", "Modern Global Conflicts", "Geopolitical Structures", "Human Rights Movements", "Cultural Revolutions", "Current Global Issues"
    ]
  }
},
 "12th Grade": {
  "subjects": {
    "Math": [
      "Pre-Calculus", "Limits and Continuity", "Derivatives and Integration", "Trigonometric Functions and Identities", "Complex Numbers", "Polynomial and Rational Functions", "Exponential and Logarithmic Functions", "Vectors and Parametric Equations", "Sequences and Series", "Matrices and Determinants", "Graphing Trigonometric and Logarithmic Functions", "Probability and Statistics"
    ],
    "Science": [
      "Environmental Science", "Ecosystems and Biodiversity", "Energy Resources and Consumption", "Water Resources and Pollution", "Air Pollution and Climate Change", "Sustainability and Conservation", "Human Population and Demographics", "Renewable and Non-Renewable Resources", "Environmental Policy and Economics", "Lab Work and Field Studies", "Scientific Research and Data Analysis"
    ],
    "Language arts": [
      "British Literature", "Analyzing Themes and Literary Movements", "Shakespearean Plays and Poetry", "Comparative Literature", "Research Papers and Critical Essays", "Expository Writing", "Argumentative Writing", "Creative Writing (Fiction, Poetry)", "Grammar (Advanced Syntax and Structure)", "Vocabulary and Etymology", "Public Speaking and Presentations"
    ],
    "Social Studies": [
      "Government and Economics", "Foundations of American Government", "Political Parties and Electoral Systems", "The Constitution and Federalism", "Civil Liberties and Civil Rights", "Comparative Government Systems", "Microeconomics (Supply and Demand)", "Macroeconomics (Fiscal and Monetary Policy)", "Globalization and International Trade", "Current Events and Public Policy"
    ]
  }
}
};

const MockTestGenerator = () => {
  const [topic, setTopic] = useState("");
  const [difficulty, setDifficulty] = useState("easy");
  const [numQuestions, setNumQuestions] = useState(10); // Default number of questions
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [quizLimit, setQuizLimit] = useState(2); // Default limit
  const [user] = useAuthState(auth);
  const [isModalOpen, setModalOpen] = useState(false);
  const [useCustomTopic, setUseCustomTopic] = useState(true); // State to toggle between custom/free-text topic or grade/subject/topic selection
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [questionType,setQuestionType] = useState("text")
  const [filteredTopics, setFilteredTopics] = useState([]);
  const database = getDatabase();
  var tool= { name: "Mock Test Generator"}

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setQuizLimit(userData.quizLimit || 2); // Initialize limit if missing
        }
      };
      fetchUserData();
    }
  }, [user, database]);

  // Handle generating quiz in batches of 10
  const handleGenerateQuiz = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }
    setError("");
    setLoading(true);

    const selectedTopicText = useCustomTopic ? topic : selectedTopic;
    if (!selectedTopicText.trim()) {
      setError("Please select a topic.");
      setLoading(false);
      return;
    }

    const totalQuestions = numQuestions;
    if(questionType=='image'){
      QUESTIONS_PER_CREDIT=5
    }
    const totalCreditsNeeded = Math.ceil(totalQuestions / QUESTIONS_PER_CREDIT); // Calculate how many credits are needed

    // Check if user has enough credits
    if (quizLimit < totalCreditsNeeded) {
      toast.error(`You need ${totalCreditsNeeded - quizLimit} more credits to generate this quiz.`);
      setLoading(false);
      return;
    }

    try {
      // Save the quiz to Firebase under the "mocktests" node
      if (user) {
        const quizRef = push(ref(database, `mocktests/${user.uid}`));
        const quizId = quizRef.key; // Get the newly generated quiz ID
        const quizData = {
          originalText: selectedTopicText,
          createdAt: new Date().toISOString(),
          questionType: questionType,
          state: 'processing', // Initially set state to 'processing'
          questions: []
        };
        await set(quizRef, quizData);
        const generatedQuiz = await generateQuiz(user.uid,user.email,quizId, selectedTopicText, difficulty, numQuestions,useCustomTopic, selectedGrade, selectedSubject, selectedTopic,questionType);
        console.log(generatedQuiz)

        toast.success(
          <>
            Quiz generation started. You will receive update on your email and{' '}
            <Link to="/document-queue" style={{ color: 'blue', textDecoration: 'underline' }}>
              Documents Queue
            </Link>{' '}
            page once Quiz is ready.
          </>
        );        
        // Deduct the required credits from the quizLimit
        const newLimit = quizLimit - totalCreditsNeeded;
        setQuizLimit(newLimit);
        const userRef = ref(database, `users/${user.uid}`);
        await update(userRef, { quizLimit: newLimit });
      }
    } catch (error) {
      console.log(error)
      setError("There was an issue generating the quiz. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle grade, subject, and topic selections
  const handleGradeChange = (e) => {
    setSelectedGrade(e.target.value);
    setSelectedSubject("");
    setSelectedTopic("");
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
    setFilteredTopics(gradesData[selectedGrade].subjects[e.target.value] || []);
    setSelectedTopic("");
  };

  const handleTopicSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setFilteredTopics(
      gradesData[selectedGrade].subjects[selectedSubject].filter((topic) =>
        topic.toLowerCase().includes(query)
      )
    );
  };
  const handleNumQuestionsChange = (value) => {
    // Ensure the value is within the allowed maximum
    const maxQuestionsAllowed = MAX_QUESTIONS;
  
    // Update the number of questions, ensuring it's capped at the max allowed value
    if (value > maxQuestionsAllowed) {
      setNumQuestions(maxQuestionsAllowed);
      toast.error(`You can only generate up to ${maxQuestionsAllowed} questions.`);
    } else {
      setNumQuestions(Math.min(value, maxQuestionsAllowed));
    }
  };
  

  return (
    <>
      <Helmet>
        <title>AI Quiz & Mock Test Generator - Student AI Tools</title>
        <meta
          name="description"
          content="Create quizzes and mock tests instantly with our free AI quiz generator for teachers. Simplify assessment with AI-powered quiz and mock test generators."
        />
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-5xl flex justify-between items-center mb-4">
        {user && (
          <div className="mb-4 text-right">
            <span className="bg-green-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Credit Left: {quizLimit}
            </span>
          </div>
          )}
      </div>

      <h1 className="text-3xl font-bold text-center mb-4">AI Quiz & Mock Test Generator</h1>


        <p className="text-lg text-gray-600 text-center mb-8">
          Create customized mock tests based on any topic to help you prepare for exams.
        </p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}


        <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        viewport={{ once: true, amount: 0.1 }}>    
    
          <form onSubmit={handleGenerateQuiz} className="space-y-4">
            {/* Toggle between free text topic or selection mode */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Choose Topic Mode</label>
              <select
                onChange={(e) => setUseCustomTopic(e.target.value === "custom")}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                <option value="custom">Free Text</option>
                <option value="select">Grade/Subject/Topic Selection</option>
              </select>
            </div>
    
            {/* Free-text topic input */}
            {useCustomTopic && (
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Enter Topic</label>
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter topic"
                  required
                />
              </div>
            )}
    
            {/* Grade/Subject/Topic selection input */}
            {!useCustomTopic && (
              <>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">Select Grade</label>
                  <select
                    value={selectedGrade}
                    onChange={handleGradeChange}
                    className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    required
                  >
                    <option value="">Select Grade</option>
                    {Object.keys(gradesData).map((grade) => (
                      <option key={grade} value={grade}>
                        {grade}
                      </option>
                    ))}
                  </select>
                </div>
    
                {selectedGrade && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">Select Subject</label>
                    <select
                      value={selectedSubject}
                      onChange={handleSubjectChange}
                      className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      required
                    >
                      <option value="">Select Subject</option>
                      {Object.keys(gradesData[selectedGrade].subjects).map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
    
                  {selectedSubject && (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">Select Topic</label>
                    <Select
                      value={filteredTopics.find((t) => t === selectedTopic) ? { value: selectedTopic, label: selectedTopic } : null}
                      onChange={(selectedOption) => setSelectedTopic(selectedOption.value)}
                      options={filteredTopics.map((topic) => ({ value: topic, label: topic }))}
                      placeholder="Search and select a topic"
                      isSearchable
                      className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>
                )}
              </>
            )}
    
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Level of Difficulty</label>
              <select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>
            </div>
    
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Number of Questions
              </label>
              <input
                type="number"
                value={numQuestions}
                onChange={(e) => handleNumQuestionsChange(Number(e.target.value))}
                className="w-full px-4 py-2 border rounded-lg mb-2"
                placeholder="Enter number of questions"
                max={MAX_QUESTIONS}
              />
              <input
                type="range"
                min="10"
                max={MAX_QUESTIONS}
                step="10"
                value={numQuestions}
                onChange={(e) => handleNumQuestionsChange(Number(e.target.value))}
                className="w-full"
              />
              <p className="text-gray-500 text-sm mt-1">Max questions: {MAX_QUESTIONS}</p>
            </div>

            {/* Question Type Selection */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Question Type</label>
              <div className="flex flex-col space-y-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="questionType"
                    value="text"
                    checked={questionType === "text"}
                    onChange={(e) => setQuestionType(e.target.value)}
                    className="form-radio"
                  />
                  <span className="ml-2">Text-Based</span>
                  <span className="ml-2 cursor-pointer group relative tooltip" data-tip="1 credit deducted for every 10 questions.">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 inline-block text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10c0 4.418-3.582 8-8 8S2 14.418 2 10 5.582 2 10 2s8 3.582 8 8zm-8 3a1 1 0 110 2 1 1 0 010-2zm1-8a1 1 0 00-2 0v5a1 1 0 002 0V5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="questionType"
                    value="image"
                    checked={questionType === "image"}
                    onChange={(e) => setQuestionType(e.target.value)}
                    className="form-radio"
                  />
                  <span className="ml-2">Image-Based</span>
                  <span className="ml-2 cursor-pointer group relative tooltip" data-tip="2 credit deducted for every 10 questions.">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 inline-block text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10c0 4.418-3.582 8-8 8S2 14.418 2 10 5.582 2 10 2s8 3.582 8 8zm-8 3a1 1 0 110 2 1 1 0 010-2zm1-8a1 1 0 00-2 0v5a1 1 0 002 0V5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="questionType"
                    value="fill-in-the-blanks"
                    checked={questionType === "fill-in-the-blanks"}
                    onChange={(e) => setQuestionType(e.target.value)}
                    className="form-radio"
                  />
                  <span className="ml-2">Fill in the Blanks</span> 
                  <span className="ml-2 cursor-pointer group relative tooltip" data-tip="1 credit deducted for every 10 questions.">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 inline-block text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10c0 4.418-3.582 8-8 8S2 14.418 2 10 5.582 2 10 2s8 3.582 8 8zm-8 3a1 1 0 110 2 1 1 0 010-2zm1-8a1 1 0 00-2 0v5a1 1 0 002 0V5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </label>
              </div>
            </div>

            <button type="submit" className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 flex justify-center items-center" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Generating...</span>
                    </div>
                  ) : "Generate Quiz"}
                </button>
          </form>
    
        </motion.div>
        
      <Feedback tool={{"name":"Mock Test Generator"}} />
    
    <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.1 }}>
      {/* Section 1: What is Mock Test Generator */}
        <h2 className="text-2xl font-bold mb-4">What is a Mock Test Generator?</h2>
        <p className="text-gray-700">
          The Mock Test Generator is an AI tool designed to help users create personalized mock tests based on specific topics and difficulty levels. Ideal for educators, students, and professionals.
        </p>

      {/* Section 2: Why Use a Mock Test Generator */}
        <h3 className="text-xl font-semibold mb-4">Why Use Mock Test Generator?</h3>
        <table className="min-w-full table-auto border-collapse border border-gray-200 mb-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 border border-gray-200">Benefit</th>
              <th className="px-4 py-2 border border-gray-200">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Efficient Test Creation</td>
              <td className="px-4 py-2 border border-gray-200">Generate tests quickly with customizable options.</td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Tailored to Your Needs</td>
              <td className="px-4 py-2 border border-gray-200">Customize tests by topic, difficulty level, and format.</td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Versatile Across Subjects</td>
              <td className="px-4 py-2 border border-gray-200">Suitable for various subjects, including math, science, and more.</td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Instant Feedback</td>
              <td className="px-4 py-2 border border-gray-200">Get immediate feedback on generated tests and answers.</td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-200 font-bold">Helps in Exam Preparation</td>
              <td className="px-4 py-2 border border-gray-200">Simulate real exams for practice and preparation.</td>
            </tr>
          </tbody>
        </table>

      {/* Section 3: Why Choose StudentAITools */}
        <h3 className="text-xl font-semibold mb-4">Why Choose StudentAITools?</h3>
        <p className="text-gray-700 mb-4">
          StudentAITools offers a comprehensive and intuitive Mock Test Generator that is easy to use, customizable, and perfect for students, teachers, and professionals. With our platform, you can create top-quality mock tests and quizzes in minutes.
        </p>
        <p className="text-gray-700 mb-4">
        Looking to enhance your classroom assessments? Use our innovative AI quiz generator that is specifically designed to create quizzes quickly and efficiently. The AI quiz generator for teachers allows you to customize questions and formats to suit your curriculum, saving you valuable time. With our free AI quiz generator, you can effortlessly generate engaging assessments for students. Additionally, our mock test generator and AI mock test generator provide easy-to-use tools for creating practice exams, helping students prepare effectively. Experience seamless quiz and test creation with our advanced mock test generator AI.
        </p>

        <h3 className="text-xl font-semibold mb-2">How to Use Our Mock Test Generator</h3>
      {/* Section 4: How to Use the Mock Test Generator */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
      <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-orange-100 rounded-full flex items-center justify-center">
            <FaClipboardList className="text-orange-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Step 1: Select Topic Mode</h3>
            <p className="text-gray-600">Select either free-text or Grade/Subject/Topic Selection</p>
          </div>
        </div>
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-purple-100 rounded-full flex items-center justify-center">
            <FaKeyboard className="text-purple-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Step 2: If Selected Free-text</h3>
            <p className="text-gray-600">Input the topic for the test.</p>
          </div>
        </div>
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
            <FaQuestionCircle className="text-blue-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Step 3: If Selected Grade/Subject/Topic</h3>
            <p className="text-gray-600">Select your Grade, then choose Subject and finally choose topic for the test.</p>
          </div>
        </div>
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
            <FaSyncAlt className="text-yellow-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Step 4: Set Difficulty</h3>
            <p className="text-gray-600">Set easy, medium, or hard levels.</p>
          </div>
        </div>
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
            <FaListAlt className="text-green-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Step 5: Choose Number of Questions</h3>
            <p className="text-gray-600">Select the number of questions you need.</p>
          </div>
        </div>
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
            <FaPen className="text-red-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Step 6: Generate and Review</h3>
            <p className="text-gray-600">Click “Generate” and review the questions.</p>
          </div>
        </div>
      </div>

      {/* Section 5: Tips for Best Results */}
        <h3 className="text-xl font-semibold mb-4">Tips for Best Results</h3>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Provide clear, well-structured topics to get relevant questions.</li>
          <li>Use the tool for a variety of subjects for versatile test creation.</li>
          <li>Review and edit the test for best accuracy and relevancy.</li>
        </ul>

      <h3 className="text-xl font-semibold mb-2">Who Can Benefit from Mock Test Generator?</h3>
      {/* Section 6: Who Can Benefit from the Mock Test Generator */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
            <FaChalkboardTeacher className="text-blue-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Teachers</h3>
            <p className="text-gray-600">Generate quizzes and tests effortlessly for classrooms.</p>
          </div>
        </div>
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
            <FaGraduationCap className="text-green-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Students</h3>
            <p className="text-gray-600">Use the tool to practice and prepare for exams.</p>
          </div>
        </div>
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
            <FaUserGraduate className="text-yellow-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Professors</h3>
            <p className="text-gray-600">Create tests for higher education students.</p>
          </div>
        </div>
        <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
          <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
            <FaSearch className="text-red-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">Researchers</h3>
            <p className="text-gray-600">Create knowledge tests for assessing research studies.</p>
          </div>
        </div>
      </div>

      {/* Section 7: Best Free Mock Test Generators */}
        <h3 className="text-xl font-semibold mb-4">Best Free Mock Test Generators</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
          {/* Tool 1 */}
          <div className="flex items-center p-4 bg-gray-50 shadow-md rounded-lg">
            <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
              <FaChalkboardTeacher className="text-blue-600" />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">TestMoz</h3>
              <p className="text-gray-600">
                A simple tool for generating online tests with customizable features.
              </p>
            </div>
          </div>

          {/* Tool 2 */}
          <div className="flex items-center p-4 bg-gray-50 shadow-md rounded-lg">
            <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
              <FaGraduationCap className="text-green-600" />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">ClassMarker</h3>
              <p className="text-gray-600">
                Allows you to create professional quizzes and assessments for any industry.
              </p>
            </div>
          </div>

          {/* Tool 3 */}
          <div className="flex items-center p-4 bg-gray-50 shadow-md rounded-lg">
            <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
              <FaUserGraduate className="text-yellow-600" />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">ProProfs Quiz Maker</h3>
              <p className="text-gray-600">
                Create quizzes and tests effortlessly for education or business purposes.
              </p>
            </div>
          </div>

          {/* Tool 4 */}
          <div className="flex items-center p-4 bg-gray-50 shadow-md rounded-lg">
            <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
              <FaSearch className="text-red-600" />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">Google Forms</h3>
              <p className="text-gray-600">
                Free and simple tool to create quizzes and assessments with instant scoring.
              </p>
            </div>
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-4">Disclaimer</h3>
        <p className="text-gray-700">
          Our AI Mock Test Generator aims for high accuracy. However, achieving perfection is still challenging. Please note: the generated tests are for demonstration purposes only. Do not rely on them for real-world applications. Use caution, as we are not liable for any damages incurred from using our tool. We work hard to minimize errors, but strongly recommend using human oversight when administering tests to ensure accuracy and reliability, especially in situations that involve academic integrity.
        </p>

      </motion.div>


        <NewsletterCard/>
        {user && (
          <BuyCreditsButton />
        )}

      </div>

     <Footer />

    <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );  
};

export default MockTestGenerator;
