import React, { useState, useEffect } from "react";
import { generateWorksheet } from "../openaiService"; // OpenAI service for generating worksheets
import { getDatabase, ref, push, set, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BuyCreditsButton from './BuyCreditsButton';
import { FaClipboard, FaKeyboard, FaPen, FaSyncAlt, FaListAlt, FaGraduationCap, FaBriefcase, FaChalkboardTeacher } from "react-icons/fa";
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import { Helmet } from 'react-helmet';
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';
import Download from "./Download";

const MAX_QUESTIONS = 20; // Maximum number of questions

const WorksheetGenerator = () => {
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [numQuestions, setNumQuestions] = useState(5); // Default number of questions
  const [worksheet, setWorksheet] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [worksheetLimit, setWorksheetLimit] = useState(2); // Free limit for unsubscribed users
  const [isSubscribed, setIsSubscribed] = useState(false); // Subscription status
  const [isModalOpen, setModalOpen] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userRef = ref(getDatabase(), `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsSubscribed(userData.subscriptionStatus === "subscribed");
          if (userData.worksheetLimit !== undefined) {
            setWorksheetLimit(userData.worksheetLimit);
          } else {
            await update(userRef, { worksheetLimit: 2 }); // Initialize limit
          }
        }
      };
      fetchUserData();
    }

    if (location.state?.worksheet) {
      setWorksheet(location.state.worksheet);
      setViewMode(true);
    } else {
      setWorksheet('');
      setViewMode(false);
    }
  }, [user, location]);

  // Handle generating the worksheet
  const handleGenerateWorksheet = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }
    setError("");
    setWorksheet("");
    setLoading(true);

    if (!subject.trim()) {
      setError("Please enter a subject and topic.");
      setLoading(false);
      return;
    }

    if (worksheetLimit <= 0) {
      toast.error(
        <span>
          Buy credits for generating more worksheets!
        </span>
      );
      setLoading(false);
      return;
    }

    try {
      const result = await generateWorksheet(subject, gradeLevel, numQuestions);
      setWorksheet(result);

      if (user) {
        const worksheetRef = push(ref(getDatabase(), `summaries/${user.uid}`));
        await set(worksheetRef, {
          originalText: subject,
          worksheet: result,
          gradeLevel,
          numQuestions,
          createdAt: new Date().toISOString(),
          tool: "Worksheet Generator"
        });

        toast.success("Saved to Documents!");

        if (!isSubscribed) {
          const newLimit = worksheetLimit - 1;
          setWorksheetLimit(newLimit);
          const userRef = ref(getDatabase(), `users/${user.uid}`);
          await update(userRef, { worksheetLimit: newLimit });
        }
      }
    } catch (error) {
      setError("There was an issue generating the worksheet. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    let worksheet = document.getElementById('answer').textContent;
    if (!worksheet) {
      toast.error("No content to download!");
      return;
    }

    const blob = new Blob([worksheet], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${subject || "generated-worksheet"}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopy = () => {
    let worksheet = document.getElementById('answer').innerHTML;
    if (!worksheet) {
      toast.error("No content to download!");
      return;
    }
    if (worksheet) {
      const blob = new Blob([worksheet], { type: 'text/html' });
      const clipboardItem = new ClipboardItem({ 'text/html': blob });

      navigator.clipboard.write([clipboardItem])
        .then(() => {
          toast.success("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
          toast.error("Failed to copy text.");
        });
    } else {
      toast.error("No text to copy!");
    }
  };
  const handleNumQuestionsChange = (value) => {
    // Ensure the value is within the allowed maximum
    const maxQuestionsAllowed = MAX_QUESTIONS;
  
    // Update the number of questions, ensuring it's capped at the max allowed value
    if (value > maxQuestionsAllowed) {
      setNumQuestions(maxQuestionsAllowed);
      toast.error(`You can only generate up to ${maxQuestionsAllowed} questions.`);
    }
    else if (value%5!=0) {
        const nearestMultipleOfFive = Math.round(value / 5) * 5;
        setNumQuestions(nearestMultipleOfFive);
        toast.error(`You can only set questions to multiple of 5.`);
    } else {
      setNumQuestions(Math.min(value, maxQuestionsAllowed));
    }
  };
    // Utility function to inject Tailwind classes into lesson plain HTML content
const applyTailwindClasses = (htmlContent) => {
    return htmlContent
      .replace(/<h1>/g, '<h1 class="text-2xl font-bold mb-4">')
      .replace(/<h2>/g, '<h2 class="text-xl font-semibold mb-3">')
      .replace(/<h3>/g, '<h3 class="text-lg font-semibold mb-2">')
      .replace(/<p>/g, '<p class="mb-4 text-base leading-relaxed">')
      .replace(/<ol>/g, '<ol class="list-decimal pl-6 mb-4">')
      .replace(/<ul>/g, '<ul class="list-disc pl-6 mb-4">')
      .replace(/<li>/g, '<li class="mb-2">');
  };

  return (
    <>
      <Helmet>
        <title>AI Worksheet Generator for Teachers - Student AI Tools</title>
        <meta
          name="description"
          content="Create customized worksheets instantly with our AI worksheet generator for teachers. Simplify lesson prep and enhance student learning with easy-to-use AI tools."
        />
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <div className="w-full max-w-5xl flex justify-between items-center mb-4">
          {viewMode && (
            <button
              onClick={() => navigate("/documents")}
              className="btn btn-active mt-4"
            >
              &larr; Back to Documents
            </button>
          )}

          {user && (
            <div className="text-right">
              <span className="bg-green-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Credit Left: {worksheetLimit}
              </span>
            </div>
          )}
        </div>

        <h1 className="text-3xl font-bold text-center mb-4">AI Worksheet Generator for Teachers</h1>
        <p className="text-lg text-gray-600 text-center mb-8">
          Enter the subject and topic, we'll generate a worksheet for you.
        </p>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        <div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full flex flex-col">

          {!viewMode && (
            <div className="w-full">
              <h2 className="text-lg font-bold mb-4">Worksheet Details</h2>

              <form onSubmit={handleGenerateWorksheet}>
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    Subject and Topic
                  </label>
                  <textarea
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    rows="2"
                    placeholder="Describe the subject and specific topic or focus area, e.g., Math - Addition, Science - Photosynthesis"
                    required
                  ></textarea>
                </div>

                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    Grade Level (Optional)
                  </label>
                  <input
                    value={gradeLevel}
                    onChange={(e) => setGradeLevel(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    placeholder="e.g., Kindergarten, 1st Grade, 2nd Grade"
                  />
                </div>

                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    Number of Questions
                  </label>
                  <input
                        type="number"
                        value={numQuestions}
                        onChange={(e) => handleNumQuestionsChange(Number(e.target.value))}
                        className="w-full px-4 py-2 border rounded-lg mb-2"
                        placeholder="Enter number of questions"
                        max={MAX_QUESTIONS}
                    />
                    <input
                        type="range"
                        min="5"
                        max={MAX_QUESTIONS}
                        step="5"
                        value={numQuestions}
                        onChange={(e) => handleNumQuestionsChange(Number(e.target.value))}
                        className="w-full"
                    />
                    <p className="text-gray-500 text-sm mt-1">Max questions: {MAX_QUESTIONS}</p>
                </div>

                <button type="submit" className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 flex justify-center items-center" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Generating...</span>
                    </div>
                  ) : "Generate"}
                </button>
              </form>
            </div>
          )}

          <div className="w-full mt-6">
            <h2 className="text-lg font-bold mb-4">AI Output</h2>

            <div className="bg-gray-100 p-4 rounded-lg h-82 relative overflow-auto">
                <div
                    className="text-gray-700"
                    id="worksheet"
                    dangerouslySetInnerHTML={{
                    __html: applyTailwindClasses(worksheet) || "Your generated worksheet will appear here...",
                    }}
                ></div>
            </div>

            <div className="flex justify-between mt-4">
              <Download doc={{ output_id: "worksheet"}} />
              {/* <button
                onClick={handleDownload}
                className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
              >
                Download
              </button> */}

              <button
                onClick={handleCopy}
                className="text-gray-500 hover:text-gray-700"
                title="Copy to Clipboard"
              >
                <FaClipboard className="text-xl text-gray-600 hover:text-blue-600" />
              </button>
            </div>
          </div>
        </div>

        <Feedback tool={{ name: "Worksheet Generator" }} />

        {!viewMode && (
        <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.1 }}>
          <h2 className="text-2xl font-bold mb-4">What is an AI Worksheet Generator?</h2>
          <p className="text-gray-700 mb-4">
            The AI Worksheet Generator helps teachers create custom, engaging, and subject-specific worksheets in a matter of seconds. Whether you need math problems, science questions, or language arts exercises, this tool provides tailored worksheets to meet your classroom needs.
          </p>

          <h3 className="text-xl font-semibold mb-2">Why Use an AI Worksheet Generator?</h3>
          <table className="min-w-full table-auto border-collapse border border-gray-200 mb-4">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 border border-gray-200">Benefit</th>
                <th className="px-4 py-2 border border-gray-200">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2 border border-gray-200 font-bold">Time-Saving</td>
                <td className="px-4 py-2 border border-gray-200">
                  Generate high-quality worksheets in seconds, freeing up time for more teaching.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-gray-200 font-bold">Variety of Question Types</td>
                <td className="px-4 py-2 border border-gray-200">
                  Includes multiple-choice, true or false, fill-in-the-blanks, and open-ended questions.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-gray-200 font-bold">Customizable Content</td>
                <td className="px-4 py-2 border border-gray-200">
                  Tailor the worksheet content to match the subject, grade level, and learning objectives.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-gray-200 font-bold">Engaging for Students</td>
                <td className="px-4 py-2 border border-gray-200">
                  The AI generates worksheets that challenge students while keeping them engaged.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 border border-gray-200 font-bold">Creative Task Integration</td>
                <td className="px-4 py-2 border border-gray-200">
                  Each worksheet includes a creative task to enhance learning through hands-on activities.
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools for Worksheet Generation?</h3>
          <p className="text-gray-700 mb-4">
            At StudentAITools, we provide easy-to-use and flexible AI tools that enhance classroom efficiency. Our Worksheet Generator is specifically designed to save time, generate high-quality worksheets, and offer teachers a variety of question types.
          </p>
          <p className="text-gray-700 mb-4">
          Make your classroom preparation more efficient with our powerful AI worksheet generator, designed specifically for educators. The AI worksheet generator for teachers allows you to create customized, engaging worksheets in just minutes, saving you valuable time. Tailor your materials to meet student needs with ease, using AI-driven tools that simplify lesson planning. Enhance your teaching efficiency and provide high-quality learning resources effortlessly with our innovative worksheet generation solution.
          </p>

          <h3 className="text-xl font-semibold mb-2">How to Use Our AI Worksheet Generator Tool</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Step 1: Select Subject and Topic */}
            <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
              <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
                <FaKeyboard className="text-blue-600" /> {/* Icon for Worksheet Topic */}
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-semibold text-gray-800">Step 1</h3>
                <p className="text-gray-600">
                  Select the subject and topic for the worksheet.
                </p>
              </div>
            </div>

            {/* Step 2: Customize Worksheet Details */}
            <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
              <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
                <FaListAlt className="text-green-600" /> {/* Icon for Worksheet Details */}
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-semibold text-gray-800">Step 2</h3>
                <p className="text-gray-600">
                  Customize the number of questions and grade level.
                </p>
              </div>
            </div>

            {/* Step 3: Generate the Worksheet */}
            <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
              <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
                <FaSyncAlt className="text-yellow-600" /> {/* Icon for Generate Button */}
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-semibold text-gray-800">Step 3</h3>
                <p className="text-gray-600">
                  Click "Generate" and receive a complete worksheet.
                </p>
              </div>
            </div>

            {/* Step 4: Review and Adjust */}
            <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
              <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
                <FaPen className="text-red-600" /> {/* Icon for Review and Adjust */}
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-semibold text-gray-800">Step 4</h3>
                <p className="text-gray-600">
                  Review the worksheet and adjust it if needed.
                </p>
              </div>
            </div>
          </div>

          <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
          <ul className="list-disc list-inside text-gray-700 mb-4">
            <li>Be specific about the subject and topic to get the most relevant questions.</li>
            <li>Customize the worksheet to match the class's learning objectives.</li>
            <li>Incorporate the creative task to add a hands-on element to the lesson.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This Worksheet Generator?</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Icon Card 1: Teachers */}
            <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
              <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
                <FaGraduationCap className="text-blue-600" /> {/* Icon for Teachers */}
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-semibold text-gray-800">Teachers</h3>
                <p className="text-gray-600">
                  Ideal for teachers who need to <strong>create engaging worksheets</strong> that cater to various learning styles.
                </p>
              </div>
            </div>

            {/* Icon Card 2: Tutors */}
            <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
              <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
                <FaChalkboardTeacher className="text-green-600" /> {/* Icon for Tutors */}
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-semibold text-gray-800">Tutors</h3>
                <p className="text-gray-600">
                  Tutors can use this tool to <strong>generate worksheets</strong> quickly for individual or group tutoring sessions.
                </p>
              </div>
            </div>
          </div>

          <h3 className="text-xl font-semibold mb-2">Best Free Worksheet Generator Tools</h3>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li><strong>StudentAITools:</strong> Offers a flexible worksheet generator for teachers across various subjects.</li>
            <li><strong>Worksheets.com:</strong> An online tool that creates worksheets for math, reading, and more.</li>
            <li><strong>Education.com:</strong> Provides printable worksheets for all grade levels and subjects.</li>
            <li><strong>SuperTeacherWorksheets:</strong> A platform for customizable worksheets with a focus on elementary education.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
          <p className="text-gray-700">
            Our AI Worksheet Generator strives for high accuracy, but it's important to review the generated content to ensure it aligns with your lesson plan. The worksheets are meant to be a guide, and we encourage you to customize them for your
            specific classroom needs. We are not liable for any discrepancies or errors in the AI-generated content. Always review and adjust the worksheets before sharing them with students.
          </p>
        </motion.div>
        )}

        <NewsletterCard />

        {user && <BuyCreditsButton />}
      </div>

      {!viewMode && <Footer />}

      <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default WorksheetGenerator;