import React, { useState, useEffect } from "react";
import { generateLessonPlan } from "../openaiService"; // Assuming you have a service for generating lesson plans
import { getDatabase, ref, push, set, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BuyCreditsButton from './BuyCreditsButton'; 
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import { Helmet } from 'react-helmet';
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { FaClipboard, FaKeyboard, FaPen, FaSyncAlt,FaChalkboardTeacher, FaListAlt, FaGraduationCap} from "react-icons/fa";
import { motion } from 'framer-motion';
import Download from "./Download";

const LessonPlanner = () => {
  const [lessonTopic, setLessonTopic] = useState("");
  const [focusingOn, setFocusingOn] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [lessonStyle, setLessonStyle] = useState("");
  const [lessonDuration, setLessonDuration] = useState("");
  const [previousLesson, setPreviousLesson] = useState("");
  const [lessonPlan, setLessonPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [user] = useAuthState(auth);
  const [planLimit, setPlanLimit] = useState(2); // Free limit for unsubscribed users
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [isSubscribed, setIsSubscribed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // To get state passed from Documents

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userRef = ref(getDatabase(), `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsSubscribed(userData.subscriptionStatus === "subscribed");
          if (userData.planLimit !== undefined) {
            setPlanLimit(userData.planLimit);
          } else {
            await update(userRef, { planLimit: 2 });
          }
        }
      };
      fetchUserData();
    }

    if (location.state?.lesson) {
        setLessonPlan(location.state.lesson);
        setViewMode(true);
    }else{
        setLessonPlan('');
        setViewMode(false);
    }

  }, [user, location]);

  const handleGenerateLessonPlan = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }

    setError("");
    setLessonPlan("");
    setLoading(true);

    if (!lessonTopic.trim()) {
      setError("Please enter a lesson topic.");
      setLoading(false);
      return;
    }

    if (planLimit <= 0) {
      toast.error("You have reached the free limit of lesson plans. Buy credits for creating more lesson plan!.");
      setLoading(false);
      return;
    }

    try {
      const result = await generateLessonPlan(lessonTopic, focusingOn, gradeLevel, lessonStyle, lessonDuration, previousLesson);
      setLessonPlan(result);

      if (user) {
        const planRef = push(ref(getDatabase(), `summaries/${user.uid}`));
        await set(planRef, {
          originalText: lessonTopic,
          summary: result,
          focusingOn: focusingOn,
          gradeLevel: gradeLevel,
          lessonStyle: lessonStyle,
          lessonDuration: lessonDuration,
          createdAt: new Date().toISOString(),
          tool: "Lesson Planner"
        });

        toast.success("Saved to Documents!");

        if (!isSubscribed) {
          const newLimit = planLimit - 1;
          setPlanLimit(newLimit);
          const userRef = ref(getDatabase(), `users/${user.uid}`);
          await update(userRef, { planLimit: newLimit });
        }
      }
    } catch (error) {
      setError("There was an issue generating the lesson plan. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    let lessonPlan = document.getElementById('lessonPlan').innerHTML;
    if (!lessonPlan) {
      toast.error("No content to download!");
      return;
    }
    if (lessonPlan) {
      const blob = new Blob([lessonPlan], { type: 'text/html' });
      const clipboardItem = new ClipboardItem({ 'text/html': blob });
  
      navigator.clipboard.write([clipboardItem])
        .then(() => {
          toast.success("Lesson Plan copied to clipboard!");
        })
        .catch((error) => {
          toast.error("Failed to copy lesson plan.");
        });
    } else {
      toast.error("No lesson plan to copy!");
    }
  };
  
  const handleDownload = () => {
    let lessonPlan1= document.getElementById('lessonPlan').textContent;
    if (!lessonPlan1) {
      toast.error("No content to download!");  // Show error if there's no lessonPlan generated
      return;
    }
  
    const blob = new Blob([lessonPlan1], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.href = url;
    link.download = `lessonPlan.txt`;  // Filename based on the topic
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Utility function to inject Tailwind classes into lesson plain HTML content
const applyTailwindClasses = (htmlContent) => {
    return htmlContent
      .replace(/<h1>/g, '<h1 class="text-2xl font-bold mb-4">')
      .replace(/<h2>/g, '<h2 class="text-xl font-semibold mb-3">')
      .replace(/<h3>/g, '<h3 class="text-lg font-semibold mb-2">')
      .replace(/<p>/g, '<p class="mb-4 text-base leading-relaxed">')
      .replace(/<ol>/g, '<ol class="list-decimal pl-6 mb-4">')
      .replace(/<ul>/g, '<ul class="list-disc pl-6 mb-4">')
      .replace(/<li>/g, '<li class="mb-2">');
  };
  
  return (
    <>
      <Helmet>
        <title>AI Lesson Plan Generator - Student AI Tools</title>
        <meta
          name="description"
          content="Create effective lessons effortlessly with our free AI lesson planner and generator. Streamline your teaching process with the best AI-powered lesson plan tools."
        />
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <div className="w-full max-w-5xl flex justify-between items-center mb-4">

        {viewMode && (
            <button
              onClick={() => navigate("/documents")}
              className="btn btn-active mt-4"
            >
              &larr; Back to Documents
            </button>
          )}

          {user && (
            <div className="text-right">
              <span className="bg-green-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Credits Left: {planLimit}
              </span>
            </div>
          )}
        </div>

        <h1 className="text-3xl font-bold text-center mb-4">AI Lesson Plan Generator</h1>
        <p className="text-lg text-gray-600 text-center mb-8">
          Enter the details, and we'll generate a complete lesson plan.
        </p>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        {!viewMode && (
        <motion.div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        viewport={{ once: true, amount: 0.1 }}>

          <form onSubmit={handleGenerateLessonPlan}>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Lesson Topic
              </label>
              <input
                type="text"
                value={lessonTopic}
                onChange={(e) => setLessonTopic(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter the general subject of the lesson (e.g., History, Science)"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Focusing On (optional)
              </label>
              <input
                type="text"
                value={focusingOn}
                onChange={(e) => setFocusingOn(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter topics to focus on (e.g., industrial revolution)"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Grade Level (optional)
              </label>
              <input
                type="text"
                value={gradeLevel}
                onChange={(e) => setGradeLevel(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter the difficulty level (e.g., 3rd grade, beginners)"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Lesson Style (optional)
              </label>
              <input
                type="text"
                value={lessonStyle}
                onChange={(e) => setLessonStyle(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter a style for the lesson (e.g., creative, lecture)"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Lesson Duration (in minutes)
              </label>
              <input
                type="number"
                value={lessonDuration}
                onChange={(e) => setLessonDuration(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter the duration of the lesson (e.g., 45 minutes)"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Previous Lesson Information (optional)
              </label>
              <textarea
                value={previousLesson}
                onChange={(e) => setPreviousLesson(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Describe the previous lesson to create a continuing plan"
                rows="4"
              ></textarea>
            </div>

            <button type="submit" className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 flex justify-center items-center" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Generating...</span>
                    </div>
                  ) : "Generate Lesson Plan"}
                </button>
          </form>
        </motion.div>
       )}

        {/* AI Output for the generated lesson plan */}
        <div className="bg-white shadow-lg rounded-lg p-8 mt-8 max-w-5xl w-full">
          <h2 className="text-lg font-bold mb-4">AI Output</h2>

          {/* generated lesson plan output */}
        <div className="bg-gray-100 p-4 rounded-lg h-82 lg:h-82 relative overflow-auto">
        <div
            className="text-gray-700"
            id="lessonPlan"
            dangerouslySetInnerHTML={{
            __html: applyTailwindClasses(lessonPlan) || "Your generated lesson plan will appear here...",
            }}
        ></div>
        </div>

          {/* Buttons below the result */}
          <div className="flex justify-between mt-4">
            {/* Download Button */}
            <Download doc={{ output_id: "lessonPlan"}} />

            {/* <button onClick={handleDownload}
              className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
            >
              Download
            </button> */}

            {/* Copy to Clipboard Button */}
            <button
              onClick={handleCopy}
              className="text-gray-500 hover:text-gray-700"
              title="Copy to Clipboard"
            >
              <FaClipboard className="text-xl text-gray-600 hover:text-blue-600" />
            </button>
          </div>
        </div>
        
        <Feedback tool={{"name":"Lesson Planner"}} />

        {!viewMode && ( 
  <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.1 }}>
    <h2 className="text-2xl font-bold mb-4">What is an AI Lesson Planner?</h2>
    <p className="text-gray-700 mb-4">
      Our AI-powered Lesson Planner helps teachers create structured, detailed, and engaging lesson plans based on the topics they choose. Whether you're preparing for a history class or a math session, the tool generates well-thought-out lesson plans with minimal effort.
    </p>

    <h3 className="text-xl font-semibold mb-2">Why Use an AI Lesson Planner?</h3>
    <table className="min-w-full table-auto border-collapse border border-gray-200 mb-4">
      <thead className="bg-gray-100">
        <tr>
          <th className="px-4 py-2 border border-gray-200">Benefit</th>
          <th className="px-4 py-2 border border-gray-200">Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-4 py-2 border border-gray-200 font-bold">Time-Saving</td>
          <td className="px-4 py-2 border border-gray-200">
            Quickly generate comprehensive lesson plans, allowing teachers to focus more on delivering the lesson than preparing it.
          </td>
        </tr>
        <tr>
          <td className="px-4 py-2 border border-gray-200 font-bold">Enhanced Structure</td>
          <td className="px-4 py-2 border border-gray-200">
            The AI ensures lesson plans follow a logical structure, including objectives, materials, activities, and assessments.
          </td>
        </tr>
        <tr>
          <td className="px-4 py-2 border border-gray-200 font-bold">Engaging Activities</td>
          <td className="px-4 py-2 border border-gray-200">
            AI suggests interactive and hands-on activities to make lessons more engaging for students.
          </td>
        </tr>
        <tr>
          <td className="px-4 py-2 border border-gray-200 font-bold">Tailored Content</td>
          <td className="px-4 py-2 border border-gray-200">
            Customize lesson plans based on grade level, duration, and teaching style.
          </td>
        </tr>
        <tr>
          <td className="px-4 py-2 border border-gray-200 font-bold">Adaptive Learning</td>
          <td className="px-4 py-2 border border-gray-200">
            The lesson planner can adapt lessons to fit the pace and learning style of your class.
          </td>
        </tr>
        <tr>
          <td className="px-4 py-2 border border-gray-200 font-bold">Plagiarism-Free</td>
          <td className="px-4 py-2 border border-gray-200">
            Generate unique lesson plans without any plagiarism concerns.
          </td>
        </tr>
      </tbody>
    </table>

    <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools for Lesson Planning?</h3>
    <p className="text-gray-700 mb-4">
      At StudentAITools, we prioritize ease of use, flexibility, and innovation. Our Lesson Planner is powered by AI, providing detailed and engaging lesson plans to save you time and effort.
    </p>
    <p className="text-gray-700 mb-4">
    Simplify your teaching workflow with our AI lesson planner, designed to help educators create detailed, engaging lessons quickly and efficiently. Our intuitive AI lesson planner generator offers personalized suggestions, allowing you to focus more on teaching and less on planning. With our free AI lesson plan generator, you can save time while crafting high-quality, tailored lesson plans that meet your classroom needs. Let AI streamline your lesson planning process and ensure every lesson is impactful and well-structured.    
    </p>

    <h3 className="text-xl font-semibold mb-2">How to Use Our AI Lesson Planner Tool</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
      {/* Step 1: Enter Lesson Topic */}
      <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
        <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
          <FaKeyboard className="text-blue-600" /> {/* Icon for Lesson Topic */}
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-semibold text-gray-800">Step 1</h3>
          <p className="text-gray-600">
            Enter the general topic for your lesson.
          </p>
        </div>
      </div>

      {/* Step 2: Customize Lesson Details */}
      <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
        <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
          <FaListAlt className="text-green-600" /> {/* Icon for Lesson Details */}
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-semibold text-gray-800">Step 2</h3>
          <p className="text-gray-600">
            Customize lesson focus, grade level, and teaching style.
          </p>
        </div>
      </div>

      {/* Step 3: Generate the Lesson Plan */}
      <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
        <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
          <FaSyncAlt className="text-yellow-600" /> {/* Icon for Generate Button */}
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-semibold text-gray-800">Step 3</h3>
          <p className="text-gray-600">
            Click "Generate" and receive a full lesson plan.
          </p>
        </div>
      </div>

      {/* Step 4: Review and Adjust */}
      <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
        <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
          <FaPen className="text-red-600" /> {/* Icon for Review and Adjust */}
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-semibold text-gray-800">Step 4</h3>
          <p className="text-gray-600">
            Review and customize the AI-generated lesson plan as needed.
          </p>
        </div>
      </div>
    </div>

    <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
    <ul className="list-disc list-inside text-gray-700 mb-4">
      <li>Be specific about the lesson topic and objectives for better results.</li>
      <li>Customize the lesson to match your teaching style and class requirements.</li>
      <li>Use the generated lesson plan as a base and modify it to fit your unique teaching needs.</li>
    </ul>

    <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This Lesson Planner?</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
      {/* Icon Card 1: Teachers */}
      <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
        <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
          <FaGraduationCap className="text-blue-600" /> {/* Icon for Teachers */}
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-semibold text-gray-800">Teachers</h3>
          <p className="text-gray-600">
            Perfect for teachers who need to create <strong>engaging and structured lesson plans</strong> in a short amount of time.
          </p>
        </div>
      </div>

      {/* Icon Card 2: Educators */}
      <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
        <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
          <FaChalkboardTeacher className="text-green-600" /> {/* Icon for Educators */}
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-semibold text-gray-800">Educators</h3>
          <p className="text-gray-600">
            Educators can use this tool to <strong>plan courses</strong> or individual lessons, saving valuable time and ensuring consistency.
          </p>
        </div>
      </div>

    </div>

    <h3 className="text-xl font-semibold mb-2">Best Free Lesson Planner Tools</h3>
    <ul className="list-disc list-inside mb-4 text-gray-700">
      <li><strong>Roshi:</strong> An AI lesson planner that crafts detailed lesson plans tailored to specific needs.</li>
      <li><strong>Planbook:</strong> A lesson planning tool that helps organize and structure lessons.</li>
      <li><strong>Teacher Aide:</strong> A tool for managing lesson plans and tracking progress throughout the academic year.</li>
      <li><strong>Common Curriculum:</strong> A comprehensive platform for creating and sharing lesson plans with colleagues.</li>
    </ul>

    <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
    <p className="text-gray-700">
      Our AI Lesson Planner strives for high accuracy, but achieving perfection in every situation is challenging. The generated lesson plans are meant to be used as a guide, and we encourage teachers to review and modify them to fit their specific classroom needs. We are not liable for any discrepancies or errors in the AI-generated content.
    </p>
  </motion.div>
)}


        <NewsletterCard/>
        
        {user && (
            <BuyCreditsButton />
        )}
      </div>
      {!viewMode && (
        <Footer />
        )}
      <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default LessonPlanner;
