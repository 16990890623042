import React, { useState, useEffect } from "react";
import { solveMathQuestion } from "../openaiService"; // OpenAI service for solving math questions
import { transcribeMathFromImage } from "../googleVisionService";
import { getDatabase, ref, push, set, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BuyCreditsButton from './BuyCreditsButton'; // Import the new BuyCreditsButton component
import { FaFileUpload, FaClipboard } from "react-icons/fa";
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import { Helmet } from 'react-helmet';
import 'katex/dist/katex.min.css';
import { addStyles, EditableMathField } from 'react-mathquill';
import { InlineMath, BlockMath } from 'react-katex';
import Latex from 'react-latex-next';
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';
import { extractText } from "../fileService";
import Download from "./Download";

addStyles(); // Initializes the styles for mathquill

const MathSolver = () => {
  const [question, setQuestion] = useState("");
  const [solution, setSolution] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [image, setImage] = useState(null); // For handling image uploads
  const [solveCount, setSolveCount] = useState(2); // Track number of free problems remaining
  const [isSubscribed, setIsSubscribed] = useState(false); // Subscription status
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMathMode, setIsMathMode] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation(); // For accessing passed state
  const database = getDatabase();
  const texSymbols = [
    // Basic arithmetic (Grade 1-3)
    {
      icon: '+', // Addition
      data: '+',
    },
    {
      icon: '-', // Subtraction
      data: '-',
    },
    {
      icon: '×', // Multiplication
      data: '\\times',
    },
    {
      icon: '÷', // Division
      data: '\\div',
    },
    
    // Fractions (Grade 4-6)
    {
      icon: '½', // Fraction
      data: '\\frac{1}{2}',
    },
    {
      icon: '&#x2044;', // General Fraction
      data: '\\frac{x}{y}',
    },
  
    // Exponents (Grade 6-8)
    {
      icon: 'x²', // Power
      data: 'x^{2}',
    },
    {
      icon: 'x³', // Cube Power
      data: 'x^{3}',
    },
    {
      icon: '&#x221A;', // Square Root
      data: '\\sqrt{x}',
    },
    {
      icon: '&#x221B;', // Cube Root
      data: '\\sqrt[3]{x}',
    },
    
    // Algebraic Symbols (Grade 8-10)
    {
      icon: 'x₁₂₃', // Subscript
      data: 'x_{123}',
    },
    {
      icon: '&#x2264;', // Less than or equal to
      data: '\\leq',
    },
    {
      icon: '&#x2265;', // Greater than or equal to
      data: '\\geq',
    },
    {
      icon: '&#x2260;', // Not equal to
      data: '\\neq',
    },
  
    // Geometry and Trigonometry (High School)
    {
      icon: '∠', // Angle
      data: '\\angle',
    },
    {
      icon: '°', // Degree
      data: '^{\\circ}',
    },
    {
      icon: 'π', // Pi symbol
      data: '\\pi',
    },
    {
      icon: 'sin', // Sine function
      data: '\\sin{x}',
    },
    {
      icon: 'cos', // Cosine function
      data: '\\cos{x}',
    },
    {
      icon: 'tan', // Tangent function
      data: '\\tan{x}',
    },
  
    // Calculus (College/University Level)
    {
      icon: '&#x222B;', // Integral
      data: '\\int_{a}^{b} x dx',
    },
    {
      icon: 'limit', // Limit
      data: '\\lim_{n \\to \\infty} a_n',
    },
    {
      icon: '&#x2202;', // Partial Derivative
      data: '\\partial',
    },
    {
      icon: '∇', // Nabla (for gradient)
      data: '\\nabla',
    },
  
    // Greek Letters (Used in Calculus and Physics)
    {
      icon: '&#x03B1;', // Alpha
      data: '\\alpha',
    },
    {
      icon: '&#x03B2;', // Beta
      data: '\\beta',
    },
    {
      icon: '&#x03B3;', // Gamma
      data: '\\gamma',
    },
  
    // Matrices and Systems of Equations (Graduate Level)
    {
      icon: '&#x2229;', // Intersection
      data: '\\cap',
    },
    {
      icon: '&#x222A;', // Union
      data: '\\cup',
    }
  ];  
  var tool= { name: "Math Solver"}
  const { id } = useParams();
  useEffect(() => {
    console.log(id)
    if (id && location.state?.solution){
      setSolution(location.state.solution); // Auto-fill the solution if passed from Documents
      setViewMode(true);
    }else{
      setSolution(''); // Auto-fill the solution if passed from Documents
      setViewMode(false);
    }

    if (user) {
      const fetchUserData = async () => {
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsSubscribed(userData.subscriptionStatus === "subscribed");
          if (userData.mathSolverLimit) {
            setSolveCount(userData.mathSolverLimit);
          } else {
            await update(userRef, { mathSolverLimit: 2 });
          }
        }
      };
      fetchUserData();
    }
  }, [user, location, database]);

  // Handle solving the math question (text or image input)
  const handleSolveMath = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }
    setError("");
    setSolution("");
    setLoading(true);

    if (solveCount <= 0) {
      toast.error(
        <span>
          Buy credits for solving more Math problems.
        </span>
      );
      setLoading(false);
      return;
    }

    try {
      let extractedText = "";

      // Use Google Cloud Vision API for image transcription
      if (image) {
        extractedText = await extractText(image);
        if (!extractedText) {
          setError("Failed to extract math question from the image.");
          setLoading(false);
          return;
        }
        toast.info("Math question transcribed from image.");
        setQuestion(extractedText);
      }

      // Use OpenAI to solve the transcribed question
      const result = await solveMathQuestion(extractedText || question.trim());
      setSolution(result);

      // Save the result to Firebase under the logged-in user's uid
      if (user) {
        const solutionRef = push(ref(database, `summaries/${user.uid}`));
        await set(solutionRef, {
          originalText: extractedText || question,
          summary: result,
          createdAt: new Date().toISOString(),
          tool: "Math Solver",
        });

        toast.success("Saved to Documents!");

        // Deduct one from the free limit
        if (!isSubscribed) {
          const newLimit = solveCount - 1;
          setSolveCount(newLimit);
          const userRef = ref(database, `users/${user.uid}`);
          await update(userRef, { mathSolverLimit: newLimit });
        }
      }
    } catch (error) {
      setError("There was an issue solving the math question. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      toast.info("Image uploaded. Click Solve to process the question.");
    }
  };

  const handleDownload = () => {
    let solution1 = document.getElementById('solution').textContent;
    if (!solution1) {
      toast.error("No content to download!");  // Show error if there's no question generated
      return;
    }
  
    const blob = new Blob([solution1], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.href = url;
    link.download = `solution.txt`;  // Filename based on the topic
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleCopy = () => {
    let solution1 = document.getElementById('solution').innerHTML;
    if (solution1) {
      const blob = new Blob([solution1], { type: 'text/html' });
      const clipboardItem = new ClipboardItem({ 'text/html': blob });
  
      navigator.clipboard.write([clipboardItem])
        .then(() => {
          toast.success("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
          toast.error("Failed to copy text.");
        });
    } else {
      toast.error("No text to copy!");
    }
  };
  
  const cleanHtml = (htmlString) => {
    // Remove the ```html and ``` code block tags
    let cleanedHtml = htmlString.replace(/```html|```/g, '');
  
    return cleanedHtml;
  };
  const handleInsertSymbol = (symbol, e) => {
    e.preventDefault(); // Prevent form submission
    setQuestion((prevLatex) => prevLatex + symbol);
  };
const formatSolution = (solution) => {
  return solution
    // Replace line breaks with <br /> tags
    .replace(/\n/g, '<br />')
    // Replace markdown bold (**) with HTML <b> tags
    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
};
const toggleMode = (e) => {
  e.preventDefault(); // Prevent form submission
  setQuestion('')
  setIsMathMode(!isMathMode);
};

  return (
    <>
    <Helmet>
        <title>AI Math Problem Solver -  Student AI Tools</title>
        <meta
          name="description"
          content="Master math with our Math Solver AI! Use our AI math problem solver to get instant solutions and explanations for all your math questions."
        />
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        {/* Back to Documents button - left-aligned */}
        <div className="w-full max-w-5xl flex justify-between items-center mb-4">
          {viewMode && (
            <button
              onClick={() => navigate("/documents")}
              className="btn btn-active mt-4"
            >
              &larr; Back to Documents
            </button>
          )}
          
          {user && (
          <div className="text-right">
            <span className="bg-green-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Credit Left: {solveCount}
            </span>
          </div>
          )}
      </div>
  
  
      <h1 className="text-3xl font-bold text-center mb-4">AI Math Problem Solver</h1>
      <p className="text-lg text-gray-600 text-center mb-8">
        Enter or Upload a question, we'll solve it for you
      </p>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

      <motion.div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full flex flex-col lg:flex-row"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      viewport={{ once: true, amount: 0.1 }}>
        {/* Original Version input */}

        {!viewMode && (
          <div className="w-full lg:w-1/2 pr-4 lg:border-r mb-4 lg:mb-0">
            <h2 className="text-lg font-bold mb-4">Enter Question</h2>
    
            <form onSubmit={handleSolveMath}>
              <div className="container mx-auto p-4">
                {isMathMode ? (
                  <>
                <div className="mb-4">
                  {texSymbols.map(symbol => (
                    <button key={symbol.data}
                      className="border p-2 m-1"
                      onClick={(e) => handleInsertSymbol(symbol.data, e)}
                      dangerouslySetInnerHTML={{ __html: symbol.icon }}
                    />
                    ))}
                </div>
                  <EditableMathField
                    latex={question}
                    onChange={(mathField) => setQuestion(mathField.latex())}
                    className="border rounded p-4"
                    style={{ minHeight: '200px', width: '100%' }}
                  />
                  </>
                ) : (
                  <textarea
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Type text here..."
                    className="border rounded p-4 w-full"
                    rows={5}
                  ></textarea>
                )}

                <div className="flex justify-between items-center mt-4">
                  <div className="flex items-center">
                    <label className="group cursor-pointer flex items-center">
                      <FaFileUpload className="text-gray-500 hover:text-gray-700 h-5 w-5 mr-2" />
                      Upload Image
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                      />
                    </label>
                  </div>

                  <button onClick={(e) => toggleMode(e)} className="mb-4 bg-green-500 text-white px-4 py-2 rounded">
                    {isMathMode ? 'Switch to Text Mode' : 'Switch to Math Mode'}
                  </button>
                </div>
                <button type="submit" className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 flex justify-center items-center" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Solving...</span>
                    </div>
                  ) : "Solve"}
                </button>
              </div>
            </form>
          </div>
        )}
  
        {/* Paraphrased Version result */}
        <div className={viewMode ? "w-full pl-4" : "w-full lg:w-1/2 pl-4"}>
          <h2 className="text-lg font-bold mb-4">Solution</h2>
  
          {/* Solved output */}
          <div className="bg-gray-100 p-4 rounded-lg h-72 lg:h-80 relative overflow-auto">
            {/* Solved text */}

            {/* <div className="text-gray-700" id="solution" dangerouslySetInnerHTML={{ __html: convertResponseToHTML(solution)  || "Your solution will appear here..." }}>
            </div> */}
            <div className="text-gray-700" id="solution">
              <Latex>{formatSolution(solution)}</Latex>
            </div>
          </div>
          {/* Buttons below the result */}
          <div className="flex justify-between mt-4">
            {/* Download Button */}
            <Download doc={{ output_id: "solution"}} />
            {/* <button
              onClick={handleDownload}
              className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
            >
              Download
            </button> */}

            {/* Copy to Clipboard Button */}
            <button
              onClick={handleCopy}
              className="text-gray-500 hover:text-gray-700"
              title="Copy to Clipboard"
            >
              <FaClipboard className="text-xl text-gray-600 hover:text-blue-600" />
            </button>
          </div>

        </div>
      </motion.div>
      
      <Feedback tool={{"name":"Math Solver"}} />
    

      {!viewMode && (
        <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.1 }}>
        <h2 className="text-2xl font-bold mb-4">What is a Math Solver?</h2>
        <p className="text-gray-700 mb-4">
          Math Solver is an advanced AI-powered tool designed to help students solve complex mathematical problems quickly and accurately. Whether you’re dealing with algebra, calculus, or geometry, a Math Solver provides step-by-step solutions to enhance your understanding and improve your problem-solving skills.
        </p>
      
        <h3 className="text-xl font-semibold mb-2">Why Use a Math Solver?</h3>
        <p className="text-gray-700 mb-4">
          Using a Math Solver saves time and effort, especially when you're stuck on a problem. It provides instant solutions to difficult equations, making learning math easier and more accessible. With detailed explanations for each step, it helps students better understand the concepts behind the solution.
        </p>
        <p className="text-gray-700 mb-4">
        Unlock your mathematical potential with our advanced Math Solver AI, designed to assist students and learners of all levels. Our intuitive AI math solver provides quick and accurate solutions to a wide range of math problems, from basic arithmetic to complex equations. With our AI math problem solver, you not only get answers but also step-by-step explanations to help you understand the concepts behind the solutions. Whether you're preparing for exams or need help with homework, our tools make learning math easier and more efficient. Embrace the power of AI and elevate your math skills today!        
        </p>
      
        <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools?</h3>
        <p className="text-gray-700 mb-4">
          StudentAITools is designed with students in mind. Our Math Solver is powered by cutting-edge AI that offers high accuracy and detailed explanations. We prioritize ease of use, making it simple to input problems and receive answers. Whether you're a high school student or preparing for advanced studies, StudentAITools ensures a smooth, reliable experience.
        </p>
      
        <h3 className="text-xl font-semibold mb-2">How to Use Our Math Solver Tool</h3>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li>Enter your math problem into the input box or upload an image of the equation.</li>
          <li>Click "Solve" and let the AI do the work.</li>
          <li>Review the step-by-step solution and learn how the answer was derived.</li>
          <li>Use the result for homework, study purposes, or to check your work.</li>
        </ul>
      
        <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
        <ul className="list-disc list-inside text-gray-700">
          <li>Ensure the math problem is clearly written or typed for the best accuracy.</li>
          <li>For word problems, break them down into mathematical expressions for easier solutions.</li>
          <li>Use the step-by-step explanation to understand the logic behind the solution.</li>
          <li>Practice regularly with a variety of problems to improve your math skills over time.</li>
        </ul>
      
        <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This Math Solver Tool?</h3>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li>Students at all levels needing help with math homework or study.</li>
          <li>Parents looking to assist their children with math problems.</li>
          <li>Tutors and educators who want to provide quick and accurate solutions.</li>
          <li>Professionals working with mathematical equations in fields like engineering or finance.</li>
        </ul>
      
        <h3 className="text-xl font-semibold mb-2">Best Free Math Solver Tools</h3>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li><strong>Wolfram Alpha:</strong> A powerful tool that provides detailed solutions to a wide range of mathematical problems.</li>
          <li><strong>Microsoft Math Solver:</strong> An easy-to-use app that solves math problems by taking a picture or typing them in.</li>
          <li><strong>Symbolab:</strong> Offers step-by-step solutions for various mathematical topics, including algebra, calculus, and trigonometry.</li>
          <li><strong>Photomath:</strong> Allows users to take photos of handwritten math problems and provides instant solutions with explanations.</li>
          <li><strong>Mathway:</strong> A versatile math solver for various levels of mathematics, from basic algebra to advanced calculus.</li>
        </ul>
      
        <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
        <p className="text-gray-700">
          Our AI Math Solver tool aims for high accuracy. However, achieving perfection is still challenging. Please note: the predictions are for demonstration purposes only. Do not rely on them for real-world applications. Use caution, as we are not liable for any damages incurred. We work hard to minimize false positives. This is especially important in situations requiring academic integrity, to avoid false accusations. We strongly recommend using human oversight when using our products.
        </p>
      </motion.div>      
      )}
      
        <NewsletterCard/>
      
      {user && (
        <BuyCreditsButton />
      )}
    </div>
    
    {!viewMode && (
     <Footer />
    )}

    <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />

    </>
  );
};

export default MathSolver;