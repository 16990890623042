import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import { getDatabase, ref, update, get, remove } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BuyCreditsButton from './BuyCreditsButton'; // Import the new BuyCreditsButton component
import { FaShareAlt, FaDownload } from "react-icons/fa";
import JSZip from "jszip"; // Library to create ZIP files
import { saveAs } from "file-saver"; // Library to trigger file download
import Swal from "sweetalert2";

const Quiz = () => {
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(null);
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [quizStatus, setQuizStatus] = useState("Not Started");
  const [showPopup, setShowPopup] = useState(false);
  const [scormVersion, setScormVersion] = useState("scorm12");
  const location = useLocation();
  const navigate = useNavigate();
  const [quizData, setquizData] = useState(''); // Default to an empty array
  //const quizId = location.state?.quizId || null;
  const [user] = useAuthState(auth);
  const database = getDatabase();
  const { quizId } = useParams();

  useEffect(() => {
    if (user && quizId) {
      fetchQuizStatus();
      setViewMode(true);
    }
  }, [user, quizId]);

  const fetchQuizStatus = async () => {
    const quizRef = ref(database, `mocktests/${user.uid}/${quizId}`);
    const quizSnap = await get(quizRef);
    if (quizSnap.exists()) {
      const quizData = quizSnap.val().questions 
      setquizData(quizData);

      const quiz = quizSnap.val();
      if (quiz.score !== undefined) {
        setScore(quiz.score);
        setQuizStatus("Completed");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let calculatedScore = 0;
  
    // Loop through each question to check answers and save user answers
    quizData.forEach((question, index) => {
      const userAnswer = answers[index]; // Get user's answer for the current question
  
      // Check if the user's answer is correct and update score
      if (userAnswer === question.correctAnswer) {
        calculatedScore += 1;
      }
  
      // Save the userAnswer to the database
      if (user && quizId) {
        const questionRef = ref(database, `mocktests/${user.uid}/${quizId}/questions/${index}`);
        update(questionRef, {
          userAnswer: userAnswer || "", // Save the user's answer (empty string if undefined)
        });
      }
    });
  
    setScore(calculatedScore);
    setQuizStatus("Completed");

    const quizRef = ref(database, `mocktests/${user.uid}/${quizId}`);
    const snapshot = await get(quizRef);
    if (snapshot.exists()) {
      const quizData = snapshot.val().questions 
      setquizData(quizData);
    }
  
    // Save overall quiz status and score
    if (user && quizId) {
      const quizRef = ref(database, `mocktests/${user.uid}/${quizId}`);
      update(quizRef, {
        score: calculatedScore,
        status: "Completed",
      });
      toast.success(`Your score: ${calculatedScore}/${quizData.length} saved!`);
    }
  };
  

  const handleAnswerChange = (index, value) => {
    // Ensure answers are tracked by their index (question number)
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: value, // Update the selected answer for this particular question
    }));
  };
  

  // Helper: Generates SCORM 1.2 zip package
  const downloadBlackboardExport = () => {
    const zip = new JSZip();
  
    // XML structure for the Blackboard Export
    let manifest = `<?xml version="1.0" encoding="UTF-8"?>
    <assessment>
      <title>Quiz Export</title>
      <questions>
    `;
  
    quizData.forEach((question, index) => {
      const { question: questionText, correctAnswer, options, explanation, imageUrl } = question;
  
      manifest += `
        <question>
          <identifier>Q${index + 1}</identifier>
          <type>Multiple Choice</type>
          <questionText>
            <![CDATA[
              ${questionText}
              ${imageUrl ? `<img src="${imageUrl}" width="300" alt="Image for Question ${index + 1}"/>` : ''}
            ]]>
          </questionText>
          <answers>
      `;
  
      options.forEach(option => {
        manifest += `
            <answer ${option === correctAnswer ? 'correct="true"' : ''}>${option}</answer>
        `;
      });
  
      manifest += `
          </answers>
          <feedback>
            <![CDATA[${explanation || "No explanation provided."}]]>
          </feedback>
        </question>
      `;
  
      // Add images to the zip file if present
      // if (imageUrl) {
      //   fetch(imageUrl)
      //     .then(response => response.blob())
      //     .then(blob => {
      //       zip.file(`images/question_${index + 1}.jpg`, blob);
      //     })
      //     .catch(error => console.error('Error fetching image:', error));
      // }
    });
  
    manifest += `
      </questions>
    </assessment>
    `;
  
    // Add the manifest to the zip file
    zip.file("quiz.xml", manifest);
  
    // Generate the zip file and prompt download
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "Blackboard_Quiz.zip");
    });
  };  

  // Helper: Generates SCORM 2004 zip package
  const downloadCanvasQTI = () => {
    const zip = new JSZip();
  
    let quizDataXML = `<?xml version="1.0" encoding="UTF-8"?>
    <assessmentTest xmlns="http://www.imsglobal.org/xsd/imsqti_v2p2"
                    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                    xsi:schemaLocation="http://www.imsglobal.org/xsd/imsqti_v2p2 
                    http://www.imsglobal.org/xsd/imsqti_v2p2.xsd"
                    identifier="quiz"
                    title="Quiz Export">
    `;
  
    quizData.forEach((question, index) => {
      const { question: questionText, correctAnswer, options, explanation, imageUrl } = question;
  
      quizDataXML += `
      <assessmentItem identifier="item${index + 1}" title="Question ${index + 1}" adaptive="false" timeDependent="false">
        <itemBody>
          <p>${questionText}</p>
          ${imageUrl ? `<img src="${imageUrl}" width="300" alt="Question Image"/>` : ''}
          <choiceInteraction responseIdentifier="RESPONSE" shuffle="true" maxChoices="1">`;
  
      options.forEach((option, idx) => {
        quizDataXML += `
            <simpleChoice identifier="choice${idx + 1}">${option}</simpleChoice>`;
      });
  
      quizDataXML += `
          </choiceInteraction>
        </itemBody>
        
        <responseDeclaration identifier="RESPONSE" cardinality="single" baseType="identifier">
          <correctResponse>
            <value>choice${options.indexOf(correctAnswer) + 1}</value>
          </correctResponse>
        </responseDeclaration>
  
        <modalFeedback identifier="generalFeedback" outcomeIdentifier="FEEDBACK" showHide="show">
          <p>${explanation || "No explanation provided."}</p>
        </modalFeedback>
      </assessmentItem>
      `;
  
      // Add images to the zip file if present
      // if (imageUrl) {
      //   fetch(imageUrl)
      //     .then(response => response.blob())
      //     .then(blob => {
      //       zip.file(`images/question_${index + 1}.jpg`, blob);
      //     })
      //     .catch(error => console.error('Error fetching image:', error));
      // }
    });
  
    quizDataXML += `</assessmentTest>`;
  
    // Add the quiz XML to the zip file
    zip.file("quiz.xml", quizDataXML);
  
    // Generate the zip file and prompt download
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "QTI_Quiz.zip");
    });
  };
  

  const downloadJSON = () => {
    const fileData = JSON.stringify(quizData, null, 2);
    const blob = new Blob([fileData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'quiz_data.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadMoodleXML = () => {
    let xmlData = `<?xml version="1.0" encoding="UTF-8"?>
  <quiz>`;
  
    quizData.forEach((question, index) => {
      const { correctAnswer, imageUrl, options, explanation, question: questionText } = question;
  
      xmlData += `
      <question type="multichoice">
        <name>
          <text>Question ${index + 1}</text>
        </name>
        <questiontext format="html">
          <text><![CDATA[${questionText}
            ${imageUrl ? `<br><img src="${imageUrl}" width="300" alt="question image" />` : ''}
          ]]></text>
        </questiontext>
        <generalfeedback format="html">
          <text><![CDATA[${explanation || "No explanation provided."}]]></text>
        </generalfeedback>
        <defaultgrade>1</defaultgrade>
        <penalty>0.33</penalty>
        <hidden>0</hidden>
        <single>true</single>
        <shuffleanswers>true</shuffleanswers>
        <answernumbering>abc</answernumbering>`;
  
      options.forEach((option, optionIndex) => {
        const fraction = option === correctAnswer ? "100" : "0";
        xmlData += `
        <answer fraction="${fraction}" format="html">
          <text><![CDATA[${option}]]></text>
          <feedback format="html">
            <text></text>
          </feedback>
        </answer>`;
      });
  
      xmlData += `</question>`;
    });
  
    xmlData += `</quiz>`;
  
    const blob = new Blob([xmlData], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'quiz_data_moodle.xml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };  

  const resetQuiz = async () => {
    // Reset quiz score and answers
  
    // Update the quiz status in the database
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reset it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (user && quizId) {
            setScore(null);
            setAnswers({}); // Reset answers state
            const quizRef = ref(getDatabase(), `mocktests/${user.uid}/${quizId}`);
            const scoreRef = ref(getDatabase(), `mocktests/${user.uid}/${quizId}/score`);
            await update(quizRef, { status: "Not Started" });
            await remove(scoreRef);
            Swal.fire("Reset!", "Your quiz has been reset successfully.", "success");
          }
        }
      });
    } catch (error) {
      toast.error("Error resetting quiz");
    }
  };
  
  

  if (quizData.length === 0) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full text-center">
          <p className="text-red-500">No quiz data available. Please try again later.</p>
          <button
            onClick={() => navigate("/documents")}
            className="mt-4 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300"
          >
            Back to Documents
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full relative">
        <div className="flex justify-between">
          {viewMode && (
            <button
              onClick={() => navigate("/documents")}
              className="mb-4 btn btn-active mt-4"
            >
              &larr; Back to Documents
            </button>
          )}
          
          <button className="btn mb-4 btn-active mt-4" onClick={() => setShowPopup(true)}>
            <FaDownload
            />
            Export
          </button>
        </div>
  
        <h2 className="text-2xl font-bold text-center mb-6">Quiz</h2>

      <div className="flex justify-between mb-4">
        {/* Add Reset Button here */}
        {score !== null && (
          <button
            onClick={resetQuiz}
            className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300"
          >
            Reset Quiz
          </button>
        )}
        {score !== null && (
          <div className="bg-green-100 text-gray-800 px-4 py-2 rounded">
            Score: {score} points
          </div>
        )}

      </div>
  
        {showPopup && ( 
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
    <div className="relative bg-white rounded-lg p-6 max-w-2xl w-full">
      {/* Cross button for closing the popup */}
      <button
        onClick={() => setShowPopup(false)}
        className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <h2 className="text-lg font-bold mb-4">Download Options</h2>
      <div className="flex justify-around">
        <div className="border rounded-lg p-4">
          <h3 className="font-semibold mb-2">LMS download</h3>
          <p className="text-sm text-gray-600 mb-2">
            To be used in an LMS (Moodle, Canvas, Blackboard)
          </p>
          <label className="block mb-2">
            <input
              type="radio"
              name="scormType"
              value="Moodle"
              onChange={() => setScormVersion("Moodle")}
              checked={scormVersion === "Moodle"}
              className="mr-2"
            />
            Moodle XML format
          </label>
          <label className="block mb-4">
            <input
              type="radio"
              name="scormType"
              value="Canvas"
              onChange={() => setScormVersion("Canvas")}
              checked={scormVersion === "Canvas"}
              className="mr-2"
            />
            Canvas QTI XML format
          </label>
          <label className="block mb-4">
            <input
              type="radio"
              name="scormType"
              value="Canvas"
              onChange={() => setScormVersion("Blackboard")}
              checked={scormVersion === "Blackboard"}
              className="mr-2"
            />
            Blackboard XML format
          </label>
          <button
            className="bg-customGreen text-white px-4 py-2 rounded-lg hover:bg-[#0ac882] transition duration-300"
            onClick={scormVersion === "Moodle" ? downloadMoodleXML :(scormVersion === "Blackboard" ? downloadBlackboardExport:downloadCanvasQTI)}
          >
            Export
          </button>
        </div>

        <div className="border rounded-lg p-4">
          <h3 className="font-semibold mb-2">Regular download</h3>
          <p className="text-sm text-gray-600 mb-2">
            Json file can be imported to StudentAI Tools
          </p>
          <button
            className="bg-customGreen text-white px-4 py-2 rounded-lg hover:bg-[#0ac882] transition duration-300"
            onClick={downloadJSON}
          >
            Regular download
          </button>
        </div>
      </div>
    </div>
  </div>
)}

    <form onSubmit={handleSubmit}>
      {quizData.map((question, index) => (
        <div key={index} className="mb-4">
          {/* Display image above question if imageUrl exists */}
          {question.imageUrl && (
            <div className="mb-4">
              <img
                src={question.imageUrl}
                alt={`Image for question ${index + 1}`}
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div>
          )}

          <p className="mb-2 text-gray-700">{index + 1}. {question.question.replace(/^\d+\.\s*/, "")}</p>

          {question.options.map((option, idx) => (
            <div key={idx}>
              <label className="inline-flex items-center w-full mb-4">
              <button type="button"
      key={option}
      onClick={() => handleAnswerChange(index, option)}
      disabled={score !== null && option !== question.correctAnswer && option !== question.userAnswer} // Disable wrong answers after submission, keep correct answer clickable
      className={`btn btn-block ${
        answers[index] === option
          ? "btn-neutral" // Highlight selected option
          : score !== null
          ? option === question.correctAnswer
            ? "btn-success text-white font-bold" // Highlight correct answer in green after submission
            : option === question.userAnswer && option !== question.correctAnswer
            ? "btn-error text-white font-bold" // Highlight user's wrong answer in red after submission
            : "btn-ghost" // Ghost button for other options
          : "btn-outline" // Default button for unselected options
      }`}
    >
      {option}
    </button>
              </label>
            </div>
          ))}

          {/* Show explanation and correct answer if the quiz is submitted */}
          {score !== null && (
            <div className="mt-2 p-2 bg-gray-200 text-gray-700 rounded-lg shadow-sm">
              <strong>Correct Answer:</strong> {question.correctAnswer}
              <br />
              <strong>Explanation:</strong> {question.explanation || "No explanation available."}
            </div>
          )}
        </div>
      ))}
      <button
        type="submit"
        className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300"
      >
        Submit Quiz
      </button>
    </form>

  
        {score !== null && (
          <div className="mt-4 text-center">
            <p className="text-xl font-semibold">Your score: {score}/{quizData.length}</p>
          </div>
        )}
  
      </div>
      <BuyCreditsButton />
    </div>
  );  

};

export default Quiz;