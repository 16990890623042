import React, { useState, useEffect } from "react";
import { auth } from "../firebase"; // Firebase auth
import { getDatabase, ref, set, get, update } from "firebase/database"; // Firebase Realtime Database
import { useAuthState } from "react-firebase-hooks/auth";
import { updateEmail, updatePassword, updateProfile } from "firebase/auth";
import { PayPalButton } from "react-paypal-button-v2"; // PayPal button for subscription
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BuyCreditsButton from './BuyCreditsButton'; // Import the new BuyCreditsButton component

const Settings = () => {
  const [user] = useAuthState(auth);
  const [name, setName] = useState(user?.displayName || "");
  const [email, setEmail] = useState(user?.email || "");
  const [password, setPassword] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false); // Subscription status
  const [subscriptionPlan, setSubscriptionPlan] = useState("yearly"); // Default plan is yearly
  const [loadingSubscription, setLoadingSubscription] = useState(true); // Loading state for subscription
  const [credits, setCredits] = useState(0); // Credit balance for each tool
  const database = getDatabase();

  // Fetch subscription status and plan from database
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      if (user) {
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsSubscribed(userData.subscriptionStatus === "subscribed");
          setSubscriptionPlan(userData.subscriptionPlan || "yearly"); // Pre-fill subscription plan if saved
          setCredits({
            summarizeLimit: userData.summarizeLimit || 0,
            answerLimit: userData.answerLimit || 0,
            mathSolverLimit: userData.mathSolverLimit || 0,
            quizLimit: userData.quizLimit || 0,
            essayLimit: userData.essayLimit || 0,
            paraphraseLimit: userData.paraphraseLimit || 0,
            homework_helper_message_limit: userData.homework_helper_message_limit || 0,
            worksheetLimit: userData.worksheetLimit || 0,
            planLimit: userData.planLimit || 0,
            teacher_assistant_message_limit: userData.teacher_assistant_message_limit || 0
          });
        } else {
          setIsSubscribed(false);
          setSubscriptionPlan("yearly"); // Default to yearly if no data exists
        }
      }
      setLoadingSubscription(false); // Stop loading once data is fetched
    };

    fetchSubscriptionData();
  }, [user, database]);

  const handleUpdateProfile = async () => {
    try {
      if (user) {
        // Update display name
        await updateProfile(user, { displayName: name });

        // Update email
        if (email !== user.email) {
          await updateEmail(user, email);
        }

        // Update password
        if (password) {
          let result = await updatePassword(user, password);
          console.log(result);
        }

        toast.success("Profile updated successfully!");
      }
    } catch (error) {
      toast.error("Error updating profile: " + error.message);
    }
  };
  // Function to update tool limits in the database based on the subscription plan
  const updateLimitsInDatabase = async (plan) => {
    const limits = {
      monthly: 30, // 30 uses for monthly plan
      yearly: 360, // 360 uses for yearly plan
    };
    
    const limit = plan === "monthly" ? limits.monthly : limits.yearly;
    
    if (user) {
      const userRef = ref(database, `users/${user.uid}`);
      await update(userRef, {
        summarizeLimit: limit,
        essayLimit: limit,
        mathSolverLimit: limit,
        quizLimit: limit,
        answerLimit: limit,
      });
    }
  };
  const handleSubscription = async (status) => {
    const userRef = ref(database, `users/${user.uid}`);
    
    if (status === "subscribe") {
      await update(userRef, { subscriptionStatus: "subscribed", subscriptionPlan });
      setIsSubscribed(true);
      updateLimitsInDatabase(subscriptionPlan); // Update limits based on plan
      toast.success(`Subscribed to ${subscriptionPlan} plan!`);
    } else if (status === "cancel") {
      await update(userRef, { subscriptionStatus: "cancelled" });
      setIsSubscribed(false);
      toast.info("Subscription cancelled.");
    } else if (status === "renew") {
      await update(userRef, { subscriptionStatus: "subscribed", subscriptionPlan });
      setIsSubscribed(true);
      toast.success(`Subscription renewed for ${subscriptionPlan} plan!`);
    }
  };

  const handlePlanChange = (e) => {
    setSubscriptionPlan(e.target.value);
  };

  const getPayPalPlanId = () => {
    return subscriptionPlan === "monthly"
      ? "P-32S85957YE518603AM3Q5MUA" // Replace with your monthly plan ID
      : "P-9UT02797BF3921140M3Q5NFA";  // Replace with your yearly plan ID
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
        <h2 className="text-2xl font-bold text-center mb-6">Settings</h2>

        {/* Name */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* Password */}
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-700">New Password</label>
          <input
            type="password"
            placeholder="Enter new password (optional)"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* Update Profile Button */}
        <button
          onClick={handleUpdateProfile}
          className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 mb-4"
        >
          Update Profile
        </button>

        {/* Subscription Section */}
        {/* <div className="bg-gray-100 p-4 rounded-lg mb-4">
          <h3 className="text-lg font-bold mb-2">Subscription Plan</h3>

          {loadingSubscription ? (
            <p>Loading subscription status...</p>
          ) : (
            <>
              {isSubscribed ? (
                <>
                  <p className="text-gray-700 mb-2">You are currently subscribed to the {subscriptionPlan} plan.</p>
                  <button
                    onClick={() => handleSubscription("cancel")}
                    className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300 mb-2"
                  >
                    Cancel Subscription
                  </button>
                  <button
                    onClick={() => handleSubscription("renew")}
                    className="w-full bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
                  >
                    Renew Subscription
                  </button>
                </>
              ) : (
                <>
                  <p className="text-gray-700 mb-2">You are not subscribed to any plan.</p>

                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700">Choose a Plan</label>
                    <div className="flex items-center space-x-4">
                      <label>
                        <input
                          type="radio"
                          value="monthly"
                          checked={subscriptionPlan === "monthly"}
                          onChange={handlePlanChange}
                        />
                        <span className="ml-2">Monthly - $5/month</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="yearly"
                          checked={subscriptionPlan === "yearly"}
                          onChange={handlePlanChange}
                        />
                        <span className="ml-2">Yearly - $49.99/year</span>
                      </label>
                    </div>
                  </div>

                  <PayPalButton
                    options={{
                      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID, // Replace with your PayPal Client ID
                      currency: "USD",
                    }}
                    style={{ layout: "vertical" }}
                    createSubscription={(data, actions) => {
                      return actions.subscription.create({
                        plan_id: getPayPalPlanId(),
                      });
                    }}
                    onApprove={(data, actions) => {
                      handleSubscription("subscribe");
                      toast.success("Subscription successful!");
                    }}
                    onError={(err) => {
                      toast.error("Error processing PayPal payment: " + err);
                    }}
                  />
                </>
              )}
            </>
          )}
        </div> */}
        
        {/* Credit Balances */}
        <div className="bg-gray-100 p-6 rounded-lg mb-4">

        <div className="flex justify-between mb-4">
          <h3 className="text-lg font-bold mb-4">Credit Balance</h3>
          <BuyCreditsButton />
        </div>
          <table className="min-w-full bg-white rounded-lg shadow-lg">
            <thead>
              <tr>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700 bg-gray-200">Tool</th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700 bg-gray-200">Credits</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">Note Summarizer</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.summarizeLimit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">Essay Writer</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.essayLimit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">Math Solver</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.mathSolverLimit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">Quiz/Mock Test Generator</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.quizLimit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">Bullet Point Answer Generator</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.answerLimit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">AI Paraphrasing Tool</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.paraphraseLimit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">AI Homework Helper</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.homework_helper_message_limit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">AI Lesson Planner</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.planLimit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">AI Worksheet Generator</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.worksheetLimit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">AI Teacher's Assistant</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.teacher_assistant_message_limit} credits
                  </span>
                </td>
              </tr>
              <tr>
                <td className="py-3 px-4 text-sm font-medium text-gray-700">Learn with Podcast</td>
                <td className="py-3 px-4">
                  <span className="bg-green-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {credits.podcastLimit} credits
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

          {/* Importing and using the floating BuyCreditsButton component */}
        <BuyCreditsButton />

      </div>
    </div>
  );
};

export default Settings;