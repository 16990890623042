import React from 'react';

const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '26px' }}>
      <div style={{
        width: '9px',
        height: '9px',
        margin: '5px 0px 0px 5px',
        borderRadius: '50%',
        backgroundColor: 'rgb(163, 161, 161)',
        opacity: 1,
        animation: 'bouncing-loader 0.4s ease 0.1s infinite alternate',
      }}></div>
      <div style={{
        width: '9px',
        height: '9px',
        margin: '5px 0px 0px 5px',
        borderRadius: '50%',
        backgroundColor: 'rgb(163, 161, 161)',
        opacity: 1,
        animation: 'bouncing-loader 0.4s ease 0.2s infinite alternate',
      }}></div>
      <div style={{
        width: '9px',
        height: '9px',
        margin: '5px 0px 0px 5px',
        borderRadius: '50%',
        backgroundColor: 'rgb(163, 161, 161)',
        opacity: 1,
        animation: 'bouncing-loader 0.4s ease 0.3s infinite alternate',
      }}></div>
    </div>
  );
};

export default Loader;