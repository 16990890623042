import React, { useState, useEffect }  from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Signup from "./components/Signup";
import NoteSummarizer from "./components/NoteSummarizer";
import MathSolver from "./components/MathSolver";
import BulletPointAnswerGenerator from "./components/BulletPointAnswerGenerator";
import MockTestGenerator from "./components/MockTestGenerator";
import Quiz from "./components/Quiz";
import Documents from "./components/Documents";
import ProtectedRoute from "./components/ProtectedRoute";
import EssayWriter from "./components/EssayWriter";
import Settings from "./components/Settings";
import Reports from "./components/Reports";
import Quillbot from "./components/Quillbot";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MockTestImporter from "./components/MockTestImporter";
import Newsletter from "./components/Newsletter";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import BlogComponent from "./components/Blog/main";
import BlogHome from "./components/Blog/BlogHome";
import LessonPlanner from "./components/LessonPlanner";
import HomeworkHelper from "./components/HomeworkHelper";
import WorksheetGenerator from "./components/WorksheetGenerator";
import TeacherAssistant from "./components/TeacherAssisstant";
import ScrollToTop from "./components/ScrollToTop";
import DocumentsQueue from "./components/DocumentsQueue";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LearnWithPodcast from "./components/LearnWithPodcast";

function App() {
  // load google recaptcha3 script
  useEffect(() => {
    ReactRecaptcha3.init("6LdWr0sqAAAAAMkxgMsvJc3eIm3l5CDz3y9wQzaB").then(
      (status) => {
        console.log(status);
      }
    );
    // const ws = new WebSocket('wss://app.studentaitools.com/socket'); // Replace with your WebSocket URL

    // ws.onopen = () => {
    //   console.log('WebSocket connected');
    // };

    // ws.onmessage = (event) => {
    //   try {
    //     const data = JSON.parse(event.data); // Attempt to parse the data as JSON
    //     if (data && data.success==false) {
    //       toast.error(data.message);  
    //     }
    //   } catch (error) {
    //     console.log('Received a non-JSON message:', event.data); // Handle non-JSON messages (plain text)
    //     // Optionally, you can display or log the plain text message here if needed
    //   }
    // };    

    // return () => ws.close(); // Clean up on component unmount

  }, []);

  return (
    <Router>
      <div className="App">
        <ScrollToTop/>
      <ToastContainer />
        <Navbar />
        <Newsletter />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/dashboard"
            element={
                <Dashboard />
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route path="/blog" element={<BlogHome/>} />
          <Route path="/blog/:title" element={<BlogComponent />} />
          <Route
            path="/note-summarizer/:id?"
            element={
                <NoteSummarizer />
            }
          />
          <Route
            path="/bullet-point-generator/:id?"
            element={
                <BulletPointAnswerGenerator />
            }
          />
          <Route
            path="/math-solver/:id?"
            element={
                <MathSolver />
            }
          />
          <Route
            path="/learn-with-podcast/:podcastId?"
            element={
                <LearnWithPodcast />
            }
          />
          <Route
            path="/essay-writer/:id?"
            element={
                <EssayWriter />
            }
          />
          <Route
            path="/lesson-planner/:id?"
            element={
                <LessonPlanner/>
            }
          />
          <Route
            path="/homework-helper/:id?"
            element={
                <HomeworkHelper/>
            }
          />
          <Route
            path="/teacher-assistant/:id?"
            element={
                <TeacherAssistant />
            }
          />
          <Route
            path="/worksheet-generator/:id?"
            element={
                <WorksheetGenerator/>
            }
          />
          <Route
            path="/quillbot/:id?"
            element={
                <Quillbot />
            }
          />
          <Route
            path="/mock-test-generator"
            element={
                <MockTestGenerator />
            }
          />
          <Route
            path="/mock-test-importer"
            element={
                <MockTestImporter />
            }
          />
          <Route
            path="/quiz/:quizId"
            element={
              <ProtectedRoute>
                <Quiz />
              </ProtectedRoute>
            }
          />
          <Route
            path="/document-queue"
            element={
              <ProtectedRoute>
                <DocumentsQueue />
              </ProtectedRoute>
            }
          />
          <Route
            path="/documents"
            element={
              <ProtectedRoute>
                <Documents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/documents/:tool"
            element={
              <ProtectedRoute>
                <Documents />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
