import React, { useEffect, useState } from "react";
import { getDatabase, ref, get, child, remove } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { format } from "date-fns";
import { FaTrash, FaEye } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BuyCreditsButton from './BuyCreditsButton'; // Import the new BuyCreditsButton component
import NoDataPlaceholder from "./NoDataPlaceholder"; // Import the placeholder component

const Documents = () => {
  const [summaries, setSummaries] = useState([]);
  const [filteredSummaries, setFilteredSummaries] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [user] = useAuthState(auth);
  const database = getDatabase();
  const navigate = useNavigate();
  const { tool = "All" } = useParams(); // Get tool from URL params
  const [selectedDocs, setSelectedDocs] = useState([]);
const [allSelected, setAllSelected] = useState(false);

  const tools = ["All", "Note Summarizer", "Bullet Point Answer Generator", "Mock Test Generator", "Math Solver", "Essay Writer", "Quillbot", "Lesson Planner", "Worksheet Generator", "Learn With Podcast"];

  useEffect(() => {
    if (user) {
      fetchSummaries();
    }
  }, [user]);

  useEffect(() => {
    handleTabChange(tool); // Update filtering when route changes
  }, [tool, summaries]);

  // Fetch quizzes and summaries from Firebase
  const fetchSummaries = async () => {
    const dbRef = ref(database);
    const summariesSnap = await get(child(dbRef, `summaries/${user.uid}`));
    const mocktestsSnap = await get(child(dbRef, `mocktests/${user.uid}`));
    const podcastsSnap = await get(child(dbRef, `podcasts/${user.uid}`));

    const summaryList = [];
    if (summariesSnap.exists()) {
      const data = summariesSnap.val();
      Object.keys(data).forEach((key) => {
        summaryList.push({ id: key, ...data[key], type: "summary" });
      });
    }

    if (mocktestsSnap.exists()) {
      const data = mocktestsSnap.val();
      Object.keys(data).forEach((key) => {
        const status = data[key].status || "Not Started";
        if (data[key].state == "completed") {
          summaryList.push({ id: key, ...data[key], type: "mocktest", status });
        }
      });
    }

    if (podcastsSnap.exists()) {
        const data = podcastsSnap.val();
        Object.keys(data).forEach((key) => {
          const status = data[key].status || "Not Started";
          if (data[key].state == "completed") {
            summaryList.push({ id: key, ...data[key], type: "podcast", status });
          }
        });
    }

    // Sort by date (latest on top)
    summaryList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setSummaries(summaryList);
    setLoading(false); // Set loading to false after fetching data
  };

  // Handle tab change for filtering and update URL
  const handleTabChange = (tab) => {
    navigate(`/documents/${tab === "All" ? "" : tab}`); // Update the URL when tab changes
    if (tab === "All") {
      setFilteredSummaries(summaries); // Show all documents
    } else {
      const filtered = summaries.filter((summary) => {
        return tab === "Mock Test Generator" ? summary.type === "mocktest" : (tab === "Learn With Podcast" ?  summary.type === "podcast" : summary.tool === tab);
      });
      setFilteredSummaries(filtered);
    }
  };

  // Handle view button click
  const handleView = (summary) => {
    console.log(summary);

    if (summary.type === "summary" && summary.tool === "Note Summarizer") {
      navigate("/note-summarizer/"+summary.id, { state: { summary: summary.summary } });
    } else if (summary.type === "summary" && summary.tool === "Bullet Point Answer Generator") {
      navigate("/bullet-point-generator/"+summary.id, { state: { answer: summary.summary } });
    } else if (summary.type === "mocktest") {
      navigate("/quiz/"+summary.id, { state: { quizData: summary.questions, quizId: summary.id } });
    } else if (summary.type === "summary" && summary.tool === "Math Solver") {
      navigate("/math-solver/"+summary.id, { state: { solution: summary.summary } });
    } else if (summary.type === "summary" && summary.tool === "Essay Writer") {
      navigate("/essay-writer/"+summary.id, { state: { essay: summary.summary } });
    }
    else if (summary.type === "summary" && summary.tool === "Quillbot") {
      navigate("/quillbot/"+summary.id, { state: { paraphrasedText: summary.summary } });
    }
    else if (summary.type === "summary" && summary.tool === "Lesson Planner") {
      navigate("/lesson-planner/"+summary.id, { state: { lesson: summary.summary } });
    }
    else if (summary.type === "summary" && summary.tool === "Worksheet Generator") {
      navigate("/worksheet-generator/"+summary.id, { state: { worksheet: summary.worksheet } });
    }
    else if (summary.type === "podcast") {
      navigate("/learn-with-podcast/"+summary.id, { state: { podcast: JSON.stringify({script: summary.script, filePath: summary.filePath}) } });
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allDocIds = summaries.map((doc) => doc.id);
      setSelectedDocs(allDocIds);
      setAllSelected(true);
    } else {
      setSelectedDocs([]);
      setAllSelected(false);
    }
  };
  
  // Handle individual checkbox change
  const handleCheckboxChange = (docId) => {
    if (selectedDocs.includes(docId)) {
      setSelectedDocs(selectedDocs.filter((id) => id !== docId));
    } else {
      setSelectedDocs([...selectedDocs, docId]);
    }
  };
  
  // Function to delete selected summaries
  const deleteSelectedSummaries = (selectedDocIds) => {
    if(selectedDocIds){
      setSelectedDocs([...selectedDocs, selectedDocIds]);
    }
    if (selectedDocs.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete them!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteSummary(selectedDocs);
        }
      });
    }
  };
  
  // Updated deleteSummary function
  const deleteSummary = async (selectedDocIds) => {
    const idToken = await user.getIdToken();
    selectedDocIds.forEach((docId) => {
      const doc = summaries.find(d => d.id === docId);
      let type = doc.type;
      const node = type === "mocktest" ? "mocktests" : "summaries";
      if (user) {
        const summaryRef = ref(database, `${node}/${user.uid}/${docId}`);
          remove(summaryRef).then(() => {
            //Swal.fire("Deleted!", "Your summary has been deleted.", "success");
            fetchSummaries(); // Refresh the list after deletion
          });
      }
    });
  
  };

  return (
    <>
    <div className="min-h-screen flex flex-col items-center justify-start bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-5xl">
      <div className={selectedDocs.length>0?'flex justify-between':'flex justify-center'}>
          <h2 className="text-2xl font-bold text-center mb-6">Documents</h2>
          {selectedDocs.length>0 && <button className="btn btn-sm btn-error" onClick={deleteSelectedSummaries}>Remove
          <FaTrash />
          </button> }
        </div>

        {/* Tabs for filtering tools */}
        <div className="tabs flex flex-wrap justify-center mb-6">
          {tools.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabChange(tab)}
              className={`mx-1 my-1 px-4 py-2 rounded-full text-sm ${
                tool === tab ? "bg-green-600 text-white" : "bg-gray-200 text-gray-700"
              }`}
            >
              {tab === 'Quillbot' ? "AI Paraphrasing Tool" : tab}
            </button>
          ))}
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
          </div>
        ) : filteredSummaries.length === 0 ? (
          <NoDataPlaceholder /> // Show placeholder when no data
        ) : (
          <div className="overflow-x-auto w-full">
            <table className="table">
              <thead className="bg-gray-200 text-gray-600 uppercase text-sm">
                <tr>
                  <th>
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={handleSelectAll}
                        checked={allSelected}
                      />
                    </label>
                  </th>
                  <th className="py-3 px-4 text-left">Name</th>
                  <th className="py-3 px-4 text-left">Tool</th>
                  <th className="py-3 px-4 text-left">Date</th>
                  <th className="py-3 px-4 text-left">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {filteredSummaries.map((summary) => (
                  <tr key={summary.id} className="border-b border-gray-200">
                    <th>
                      <label>
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={selectedDocs.includes(summary.id)}
                          onChange={() => handleCheckboxChange(summary.id)}
                        />
                      </label>
                    </th>
                    <td className="py-3 px-4 break-words">
                      {summary.originalText === 'Quillbot' ? "AI Paraphrasing Tool" : 
                      (summary.type === 'podcast' ? summary.topic.split(" ").slice(0, 20).join(" ") :
                      summary.originalText.split(" ").slice(0, 20).join(" ") )
                      }
                      {summary.type === "mocktest" && (
                        <>
                        <br />
                        <span
                          className={`ml-2 px-2 py-1 text-xs rounded ${
                            summary.status === "Completed" ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {summary.status}
                        </span>
                        </>
                      )}
                    </td>
                    <td className="py-3 px-4">{summary.tool || "Mock Test Generator"}</td>
                    <td className="py-3 px-4">{format(new Date(summary.createdAt), "PPpp")}</td>
                    <td className="py-3 px-4 flex space-x-4">
                      <button onClick={() => handleView(summary)} className="btn btn-circle text-blue-500 hover:text-blue-700">
                        <FaEye />
                      </button>
                      <button onClick={() => deleteSelectedSummaries([summary.id])} className="btn btn-circle text-red-500 hover:text-red-700">
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <BuyCreditsButton />
    </div>
    </>
  );
};

export default Documents;

