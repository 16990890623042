import { FaFileAlt } from "react-icons/fa";

const NoDataPlaceholder = () => {
  return (
    <div className="flex flex-col items-center justify-center h-64 bg-gray-100 p-4 rounded-lg">
      <FaFileAlt className="text-gray-400 text-5xl mb-4" />
      <h2 className="text-gray-500 text-lg">No Data Available</h2>
    </div>
  );
};

export default NoDataPlaceholder;
